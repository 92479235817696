import React from "react";

const ElerbeDie = () => {
  return (
    <div>
      <div
        className="container"
        style={{
          padding: "1rem",
        }}
      >
        <p
          style={{
            color: "white",
          }}
        >
          Erlebe die unübertroffene Fähigkeiten unserer Muay Thai Kämpfer in
          Aktion. Diese engagierten Athleten verkörpern Stärke, Technik und
          einen unbeugsamen Geist. Von lokalen Kämpfen bis hin zu
          internationalen Bühnen dominieren unsere Kämpfer den Ring mit ihrer
          Kraft und ihrem Können und hinterlassen einen bleibenden Eindruck in
          der Welt der Kampfkünste.
        </p>
      </div>
      <div
        className="container"
        style={{
          padding: "1rem",
        }}
      >
        <p
          style={{
            color: "white",
            textAlign: "left",
          }}
        >
          Hier sind einige unserer besten Kämpfer:
        </p>
      </div>
    </div>
  );
};

export default ElerbeDie;
