import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Services/provideMain";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "formik";
import { Field } from "formik";
import bookingServices from "../../Services/services/booking";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
import Footer from "../../Footer/Footer";
import Header from "../../Header";
import useFetch from "../../Services/Apihook";
const BookingDetail = () => {
  const { state } = useAuth();
  const { loading:load, Content} = useFetch("contact-us-details");
  const { state: data } = useLocation();
  const navigate = useNavigate();
  const [init, setInit] = React.useState({
    email: "",
    phoneno: "",
    Message: "",
    name:"",
    state: "",
    city: "",
    building: "",
    address: "",
  });
  const handleFormSubmit = (values) => {
    bookingServices
      .addBooking({
        Price: data?.monthyFees,
        Address: values.address,
        Trainer: data.userId._id,
        Building: values.building,
        City: values.city,
        State: values.state,
        Message: values.message,
        Date: data.date,
        Name:values.name,
        Time: data.timer,
       email:values.email,
       
       
        UserId: data._id,

        OwnerbussnessId: data._id,
      })
      .then((val) => {
        var templateParams = {
          from_name: values.name,
          email: data.userId.email,
          name: data.userId.firstName,
        };

        emailjs
          .send(
            "service_q74htgf",
            "template_au5h79x",
            templateParams,
            "0Q1r-lMspJ69-UrFu"
          )
          .then(
            function (response) {
              console.log("SUCCESS!", response.status, response.text);
            },
            function (error) {
              console.log("FAILED...", error);
            }
          );
       
        Swal.fire({
      


          title: "Booking scheduled",
          confirmButtonColor: '#E2C657',
    background:"#151414",
          confirmButtonText: "Ok",
        });
        navigate("/");
      });
  };
  return (
    <>
     <Header Content={Content}/>
    <div class="py-5 mainBookingContainer" style={{marginTop:"100px"}}>
      <div class="card mx-auto ClientBookFormCard shadow-lg">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <Formik initialValues={init} onSubmit={handleFormSubmit}>
                {({ values, isSubmitting, validateForm, setTouched }) => (
                  <Form>
                    <h3>Fill out your details</h3>
                    <hr class="solid" />
                    <div class="row">
                      <div class="col-md-12">
                        <label for="Contact" class="form-label BookLabels">
                        Phone Number<span class="star">*</span>
                        </label>
                        <Field
                          type="tel"
                          class="form-control inputTextfields"
                          name="phoneno"
                          id="Contact"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label for="Name" class="form-label BookLabels">
                          Name<span class="star">*</span>
                        </label>
                        <Field
                          type="tel"
                          class="form-control inputTextfields"
                          name="name"
                          id="name"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label for="Address" class="form-label BookLabels">
                          Address<span class="star">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control inputTextfields"
                          id="address"
                          name="address"
                        />
                        <label for="City" class="form-label BookLabels">
                          City<span class="star">*</span>
                        </label>
                        <Field
                          name="city"
                          type="text"
                          class="form-control inputTextfields"
                          id="City"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="Email" class="form-label BookLabels">
                          Email<span class="star">*</span>
                        </label>
                        <Field
                          type="email"
                          class="form-control inputTextfields"
                          id="Email"
                          name="email"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label for="Message" class="form-label BookLabels">
                          Message<span class="star">*</span>
                        </label>
                        <Field
                          name="message"
                          type="text"
                          class="form-control inputTextfields"
                          id="Message"
                        />
                      </div>
                    </div>
                    <button type="submit" class="btn paybtn btn-success batn">
                      Book Now
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div class="col-md-4">
              <h3>Booking Summary</h3>
              <hr class="solid" />
              <div class="row Paymentrow">
                <div class="col-md-6">
                  <h5 class="floatstart">
                    Trainer Name :<b>{data.userId.firstName}</b>
                  </h5>
                </div>
                <div class="col-md-6">
                  <h5 class="floatend">...</h5>
                </div>
              </div>
              <div class="row Paymentrow">
                <div class="col-md-6">
                  <h5 class="floatstart">
                    Date & Time :{data.date} {data.timer}
                  </h5>
                </div>
                <div class="col-md-6">
                  <h5 class="floatend">...</h5>
                </div>
              </div>
              <div class="row Paymentrow">
                <div class="col-md-6">
                  <h5 class="floatstart">
                    Location <b>{data.address || "Not Available"}</b>
                  </h5>
                </div>
                <div class="col-md-6">
                  <h5 class="floatend">...</h5>
                </div>
              </div>

              <hr class="solid" />

              <div class="row Paymentrow">
                <div class="col-md-6">
                  <h5 class="floatend">...</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default BookingDetail;
