import React, { Suspense } from "react";

import Footer from "../../Footer/Footer";
import Header from "../../Header";
import useFetch from "../../Services/Apihook";
import CmsService from "../../Services/CMS";
import Boxing from "./../../components/Boxing/Boxing";
import Prices from "./../../components/Price/Price";
import Schedule from "./../../components/Schedule/Schedule";
import TrainingHeadComponent from "./TrainingHeadComponent";
const Banner = React.lazy(() => import("../../components/Banner/Banner"));
const Tranings = () => {
  const [Content, setContent] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { loading: load, Content: Con } = useFetch("contact-us-details");
  React.useEffect(() => {
    document.body.style.overflowY = "hidden";
    api();
  }, []);
  const api = async () => {
    const contents = await CmsService.getGetContent(
      "traning-banners?populate=*"
    );
    setContent(contents);
    setTimeout(() => {
      setLoading(false);
      document.body.style.overflowY = "scroll";
    }, 500);
  };

  return (
    <>
      <div
        style={{
          opacity: loading ? "1" : "0",
          display: loading ? "block" : "none",
        }}
      >
        <div
          style={{
            width: "100%",

            position: "absolute",
            top: "0",
            background: "white",
            bottom: "0",
            height: "100vh",

            transition: "20000ms all fade",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1200",
          }}
        >
          <img
            src="../assets/logo2.png"
            style={{ display: loading ? "block" : "none", width: "30%" }}
          />
        </div>
      </div>
      {Content.length > 0 && (
        <div className="">
          <Header Content={Con} />

          {/* <Banner Content={Content} /> */}

          <Boxing />
          <Schedule />
          <Prices />
          <Footer />
        </div>
      )}
    </>
  );
};
export default Tranings;
