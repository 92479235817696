import React from "react";
import useFetch from "../../Services/Apihook";
import { imageurl } from "../../Services/urls";
import classes from "./Banner.module.css";
import ProgressiveImage from "react-progressive-graceful-image";

const Banner = ({ Content }) => {
  return Content.map(
    (val) => (
      <ProgressiveImage
        src={`${imageurl}${val.attributes.bgImage.data.attributes.url}`}
        placeholder=""
      ></ProgressiveImage>
    )
    //     <div className={classes.bgpic} style={{
    //       height: '88vh',
    //   backgroundSize:' 100% 100%',
    //   display: 'flex',
    //   justifyContent:' start',
    //   alignItems: 'center!important',

    //       backgroundSize: "cover",
    //       backgroundImage: `url(${imageurl}${

    //            val.attributes.bgImage.data.attributes.url

    //       })`,
    //     }}>
    //       <div style={{ width: "35%", margin: "auto" }} className={classes.mbl}>
    //         <div  className={classes.mblhide}>
    //         <h1 className="Pb text-center">{val.attributes.Heading}</h1>

    //         <p className="text-center">
    //         {val.attributes.Description
    // }
    //         </p>
    //         </div>
    //         <div style={{textAlign:"center"}}>
    //         {/* <button className={`${classes.button} ${classes.button1} DR1`}>
    //           View Class Schedules{" "}
    //         </button> */}
    //       </div>
    //       </div>
    //     </div>
  );
};

export default Banner;
