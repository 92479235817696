import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import classes from "./AllTrainers.module.css";
import bussnessServices from "./../../Services/services/bussnessuser";
import useFetch from "../../Services/Apihook";
import TrainerWins from "./TrainerWins";
import ElerbeDie from "../../pages/Trainers/ElerbeDie";
const AllFighters = () => {
  const navigate = useNavigate();

  const { loading, Content } = useFetch(`fighters-dado-gyms?populate=*`);
  console.log(Content);
  const move = (name, value) => {
    navigate(name, { state: { info: value } });
  };
  const { state } = useLocation();
  let titlesContent = ``;

  return loading ? (
    <h1
      className={`${classes.bgcolor} `}
      style={{
        color: "white",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Loading...
    </h1>
  ) : (
    // val.attributes?.Name == name.split("-")[0] ? (

    <div className={classes.bgcolor}>
      <div className="container w-2">
        <div className="row pt-4">
          <div>
            <ElerbeDie />
          </div>
          {Content.map((val, index) => (
            <div key={"FIGHTER" + index} className="row mb-5">
              <div
                className="col-md-12 px-2   "
                style={{
                  display: "flex",
                  justifyContent: "left",
                  marginLeft: 10,
                }}
              >
                <h4
                  style={{
                    fontWeight: "bold",
                    textTransform: "capitalize",
                    color: "white",
                  }}
                  className="headings pb-4"
                >
                  {val.attributes.Name}
                </h4>
              </div>
              <div className="col-md-4">
                <div className="text-md-start text-center">
                  <img
                    style={{
                      maxWidth: "350px",
                      objectFit: "fill",
                      maxHeight: "475px",
                    }}
                    width="100%"
                    src={`https://dadocms.thecbt.live${val.attributes.image.data?.attributes?.url}`}
                  />
                </div>{" "}
              </div>

              <div
                className="col-md-8 text-white my-md-0 my-2"
                style={{
                  marginLeft: "0",
                }}
              >
                <div
                  className="d-md-flex"
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="col-md-12 d-lg-block ">
                    <div className="row">
                      <div className="text-center">
                        <table
                          className={`table table-dark table-stripped no-gutters ${classes.infoTable}`}
                        >
                          <tbody>
                            <tr style={{ background: "grey" }}>
                              <td style={{ background: "grey" }}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-sm-6 text-start">
                                      Class :
                                    </div>
                                    <div className="col-sm-6 text-start">
                                      {val.attributes.Class}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ background: "grey" }}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-sm-6 text-start">
                                      Won :
                                    </div>
                                    <div className="col-sm-6 text-start">
                                      {val.attributes.Won}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ background: "grey" }}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-sm-6 text-start">
                                      Draw :
                                    </div>
                                    <div className="col-sm-6 text-start">
                                      {val.attributes.Draw}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr style={{ background: "transparent" }}>
                              <td>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-sm-6 text-start">
                                      Weight :
                                    </div>
                                    <div className="col-sm-6 text-start">
                                      {val.attributes.Weight}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-sm-6 text-start">
                                      By K.O. :
                                    </div>
                                    <div className="col-sm-6 text-start">
                                      {val.attributes.byKO}
                                    </div>
                                  </div>
                                </div>
                              </td>

                              <td>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-6 text-start">
                                      {/* Fights : */}
                                    </div>
                                    <div className="col-md-6 text-start">
                                      {/* {val.attributes.Fights} */}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td style={{ background: "grey" }}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-6 text-start">
                                      Fights :
                                    </div>
                                    <div className="col-md-6 text-start">
                                      {val.attributes.Fights}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ background: "grey" }}>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-6 text-start">
                                      Lost :
                                    </div>
                                    <div className="col-md-6 text-start">
                                      {val.attributes.Lost}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td style={{ background: "grey" }}></td>
                            </tr>
                          </tbody>
                        </table>
                        {/* <table class="table table-dark table-striped">
                          <tr>
                            <td></td>
                            <td>Won {val.attributes.Won}</td>
                          </tr>
                          <tr>
                            <td>Weight {val.attributes.Weight}</td>
                            <td>By K.O. {val.attributes.byKO}</td>
                            <td>Fights {val.attributes.Fights}</td>
                          </tr>
                          <tr>
                            <td>Fights {val.attributes.Fights}</td>
                            <td>Lost {val.attributes.Lost}</td>
                            <td></td>
                          </tr>
                        </table>
                         */}
                      </div>
                    </div>
                  </div>

                  <div className="col-md">
                    <div className="col-md-12 text-white my-md-0 my-2">
                      <div
                        className="col-md-12 px-2   "
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          marginLeft: 10,
                        }}
                      >
                        <h4
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                          className="headings pb-4"
                        >
                          Titles
                        </h4>
                      </div>
                      {/* <div class="container" style={{ width: "60vw" }}>
                        <div class="row"> */}
                      {/* <table className="table table-dark table-stripped">
                            {val.attributes.fighters_titiles.data.map(
                              (val, index) => (
                                <tr
                                  style={{
                                    backgroundColor:
                                      index % 2 === 0 ? "grey" : "transparent",
                                  }}
                                >
                                  <td
                                    style={{
                                      backgroundColor:
                                        index % 2 === 0
                                          ? "grey"
                                          : "transparent",
                                    }}
                                  >
                                    <img
                                      src="../assets/detail/KO.svg"
                                      width={15}
                                    />{" "}
                                    {val.attributes.title}
                                  </td>
                                </tr>
                              )
                            )}
                          </table> */}

                      <table className="table table-dark table-striped">
                        <tbody>
                          {val.attributes.fighters_titiles.data
                            .filter(
                              (title, index, self) =>
                                self.findIndex(
                                  (t) =>
                                    t.attributes.title ===
                                    title.attributes.title
                                ) === index
                            ) // Remove duplicates
                            .map((title, index) => {
                              if (index % 2 === 0) {
                                return (
                                  <tr key={title.attributes.title}>
                                    <td
                                      style={{
                                        backgroundColor: "grey",
                                      }}
                                    >
                                      <img
                                        src="../assets/detail/KO.svg"
                                        width={15}
                                      />{" "}
                                      {title.attributes.title}
                                    </td>
                                    {index + 1 <
                                      val.attributes.fighters_titiles.data
                                        .length && (
                                      <td
                                        style={{
                                          backgroundColor: "grey",
                                        }}
                                      >
                                        <img
                                          src="../assets/detail/KO.svg"
                                          width={15}
                                        />{" "}
                                        {
                                          val.attributes.fighters_titiles.data[
                                            index + 1
                                          ].attributes.title
                                        }
                                      </td>
                                    )}
                                  </tr>
                                );
                              }
                            })}
                        </tbody>
                      </table>

                      {/* {val.attributes.fighters_titiles.data.map(
                            (val, index) => (
                              <TrainerWins
                                index={index + 1}
                                title={val.attributes.title}
                              />
                            )
                          )} */}
                      {/* </div>
                      </div> */}
                    </div>
                  </div>

                  <div
                    className="col-md-12 d-lg-none d-block"
                    style={{ marginTop: "20px" }}
                  >
                    {console.log(index)}
                    {console.log(Content.length)}
                    {index < Content.length - 1 ? (
                      <div
                        className="heading2 pt-4 col-md-8 col-sm-12 col-12 "
                        width="100%"
                      ></div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>{" "}
    </div>
  );
};

export default AllFighters;
