import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import DatePicker from "sassy-datepicker";
import Swal from "sweetalert2";
import moment from "moment";
import { useAuth } from "../../Services/provideMain";
import bookingServices from "../../Services/services/booking";
import Header from "../../Header";
import useFetch from "../../Services/Apihook";
const Booking = () => {
  const { loading:load, Content} = useFetch("contact-us-details");
  const [timer, changeTime] = useState("10:00 am - 11:00 am");
  const [dates, setDates] = useState(new Date());
  const [book, setBook] = React.useState([]);
  const [ori, setOri] = React.useState([]);
  const [time, setTime] = React.useState([
    "10:00 am - 11:00 am",
    "11:00 am - 12:00 pm",
    "12:00 pm - 1:00 pm",
    "1:00 pm - 2:00 pm",
    "2:00 pm  - 3:00 pm",
    "3:00 pm  - 4:00 pm",
    "4:00 pm  - 5:00 pm",
    "5:00 pm  - 6:00 pm",
    "6:00 pm  - 7:00 pm",
    "7:00 pm  - 8:00 pm",
    "8:00 pm  - 9:00 pm",
    "9:00 pm  - 10:00 pm",
    "10:00 pm  - 11:00 pm",
    "11:00 pm - 12:00 am",
    "12:00 pm - 1:00 am",
    "1:00 am - 2:00 am",
    "2:00 am - 3:00 am",
    "3:00 am - 4:00 am",
    "4:00 am - 5:00 am",
    "5:00 am - 6:00 am",
    "6:00 am - 7:00 am",
    "7:00 am - 8:00 am",
    "8:00 am - 9:00 am",
    "9:00 am - 10:00 am",
  ]);
  const [oriTime, setOriTime] = React.useState([
    "10:00 am - 11:00 am",
    "11:00 am - 12:00 pm",
    "12:00 pm - 1:00 pm",
    "1:00 pm - 2:00 pm",
    "2:00 pm  - 3:00 pm",
    "3:00 pm  - 4:00 pm",
    "4:00 pm  - 5:00 pm",
    "5:00 pm  - 6:00 pm",
    "6:00 pm  - 7:00 pm",
    "7:00 pm  - 8:00 pm",
    "8:00 pm  - 9:00 pm",
    "9:00 pm  - 10:00 pm",
    "10:00 pm  - 11:00 pm",
    "11:00 pm - 12:00 am",
    "12:00 pm - 1:00 am",
    "1:00 am - 2:00 am",
    "2:00 am - 3:00 am",
    "3:00 am - 4:00 am",
    "4:00 am - 5:00 am",
    "5:00 am - 6:00 am",
    "6:00 am - 7:00 am",
    "7:00 am - 8:00 am",
    "8:00 am - 9:00 am",
    "9:00 am - 10:00 am",
  ]);

  const navigate = useNavigate();
  console.log(ori,"withoutanyfunc");
  const location = useLocation();
  const setdatesfunc = (e) => {
    bookingServices.serviceBookings(location.state.info._id).then((val) => {
      console.log(val.Booking,"inside function");
    setBook(
      val.Booking
        .filter((val) => {
          console.log(
            val.Date.toString(),
            moment(e).format("MMM Do YY").toString()
          );
          return (
            val.status == 0 &&
            val.Date.toString() == moment(e).format("MMM Do YY").toString()
          );
        })
        .map((val) => val.Time)
    );
    const booking= ori
    .filter((val) => {
     
      return (
        val.status == 0 &&
        val.Date.toString() == moment(e).format("MMM Do YY").toString()
      );
    })
    .map((val) => val.Time)
 
    setTime(oriTime.filter((val) => !booking.includes(val)));

    setDates(e);
  })
  };
  React.useEffect(() => {
    bookingServices.serviceBookings(location.state.info._id).then((val) => {


      setOri(val.Booking);
      
     console.log(val.Booking);
      const check = val.Booking.filter((val) => {
        console.log(
          val.Date.toString(),
          moment(new Date()).format("MMM Do YY").toString()
        );
        return (
          val.status == 0 &&
          val.Date.toString() ==
            moment(new Date()).format("MMM Do YY").toString()
        );
      }).map((val) => val.Time);

      setTime(oriTime.filter((val) => !check.includes(val)));
  
    });
  }, []);
  React.useEffect(() => {
    console.log(book,222);
    setTime(oriTime.filter((val) => !book.includes(val)));
  }, [dates]);

  // const loginfirst = () => {
  //   navigate("/Logins", {
  //     state: { info: location.state.info, from: location },
  //   });
  // };
  const move = (val) => {
    navigate(val, {
      state: {
        ...location.state.info,
        date: moment(dates).format("MMM Do YY"),
        timer,
      },
    });
  };

  const saveTime = (t) => {
    changeTime(t);
  };
  return (
    <>
     <Header Content={Content}/>
      <div className="container-fluid pt-md-2 mt-md-2 " >
        <div className="row" style={{marginTop:"100px"}}>
          <div className="heading_contaier ">
            <h1 className="heading text-center">{location.state?.info?.fightName}</h1>
            <p className="heading_paragraph" >
              Request a time slot suitable for you. Your request will be
              accepted if the slot is available otherwise you will be suggested
              an alternate slot for this service.
            </p>
          </div>
          <div className="container">
            <div className="DateandTime row">
              <div className="col-md-6">
                <div className="DatePicker row">
                  <h2 className="selectedheading ">
                    Select Requested Date and Time
                  </h2>

                  <div className="selection_sector text-center">
                    <DatePicker
                      onChange={setdatesfunc}
                      selected={dates}
                      minDate={new Date(Date.now() - 100000000)}
                      className=" col-lg-6"
                    />

                    <div className="buttons col-lg-1"></div>

                    <div className="buttons">
                      <p>Timezone: Traning Time Intervel will be 1 hour from starting Time </p>

                      <select
                        onChange={(e) => saveTime(e.target.value)}
                        className="form-control"
                      >
                        {time.length > 0 &&
                          time.map((val, index) => (
                            <option value={val}>{val}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
              <div className=" col-md-5 text-md-start text-center py-md-0 py-4">
                <h2 className="selectionheading">Booking Summary</h2>
                <h6>
                  {" "}
                  <b> {location.state.info?.userId.firstName} - </b>{" "}
                </h6>
                <h6>
                  {moment(dates).format("MMM Do YY")} {timer}
                </h6>
              
                <p>$ {location.state.info.monthyFees}</p>
                <button
                  class="btn btn-success batn"
                  type="button"
                  onClick={() => move("/BookingDetail")}
                >
                  Next
                </button>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
};

export default Booking;
