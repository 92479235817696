/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";
import "./ContactStyle.css";
import { useParams, useLocation } from "react-router-dom";
//import { toast } from "react-toastify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useFetch from "../../Services/Apihook";
import CmsService from "../../Services/CMS";
import axios from "axios";

const ContactUs = () => {
  const [forms, setform] = React.useState({
    Name: "",
    Email: "",
    Description: "",
  });
  const location =
    "https://www.google.com/maps/place/Dado+Gym/@47.3832778,8.4961196,17z/data=!3m1!4b1!4m6!3m5!1s0x47900bccc8c957e9:0x9d1bcb3d29b834e4!8m2!3d47.3832778!4d8.4961196!16s%2Fg%2F11g68z408f?entry=ttu";
  const param = useLocation();
  const { Content } = useFetch("contact-us-details");
  React.useEffect(() => {
    if (param.pathname === "/ContactUs") {
      setTimeout(() => {
        const element = document.getElementById("ContactUs");

        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 2300);
    }
  }, []);
  const toastConfig = {
    position: toast.POSITION.TOP_RIGHT,
    progressClassName: "custom-progress-bar",
  };
  async function sendEmail(emailData) {
    try {
      // Make POST request to the given route with emailData as the request body
      const response = await axios.post(
        "https://dadomail.thecbt.live/send-email",
        emailData
      );

      // If the request is successful, return the response data
      return response.data;
    } catch (error) {
      // If an error occurs during the request, handle it here
      console.error("Error sending email:", error);
      throw error; // Rethrow the error to handle it at the caller level if needed
    }
  }
  const submitform = (e) => {
    e.preventDefault();
    sendEmail(forms);
    // CmsService.addContent("contact-uses", { data: forms });
    toast("Your information is submitted.", toastConfig);
    // toast("Your information is submitted", {
    //   progressBarClassName: "custom-toast-progress-bar",
    //   position: "top-right",
    //   autoClose: 5000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   progress: undefined,
    // });
    setform({ Name: "", Email: "", Description: "" });
  };
  const handler = (e) => {
    const { name, value } = e.target;

    setform({ ...forms, [name]: value });
  };

  console.log(param, "param");
  return (
    <div
      style={{
        backgroundColor: "#232222",
        paddingBottom: "70px",
        paddingTop: "70px",
      }}
      id="ContactUs"
    >
      <div className="container">
        <h3
          className="textcontrol py-3 heading"
          style={{
            fontSize: "23px",
            color: "#ddd",
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Contact Us
        </h3>
        <div></div>
        <div className="row ">
          {/*         
      <div className="col-md-6 my-3  d-flex ">
        <p className="textcontrol" style={{    color: '#ddd'}}>{Content[0]?.attributes.description}</p>

      
  
        </div> */}
          <div className="col-md-6 my-2" id="ContactUs">
            <form onSubmit={submitform}>
              <div class="row ">
                <div class="col-md-12 ">
                  <input
                    type="text"
                    name="Name"
                    value={forms.Name}
                    onChange={handler}
                    style={{ color: "gold" }}
                    class="form-control input-bg"
                    id="name"
                    placeholder="Name"
                    required
                  />
                </div>
                <div class="col-md-12">
                  <input
                    className="input-bg"
                    type="Email"
                    value={forms.Email}
                    onChange={handler}
                    style={{ color: "gold" }}
                    class="form-control input-bg"
                    name="Email"
                    placeholder="Email"
                    id="email"
                    required
                  />
                </div>
              </div>

              <div class="form-group">
                <textarea
                  class="form-control input-bg"
                  name="Description"
                  rows="5"
                  value={forms.Description}
                  onChange={handler}
                  style={{ color: "gold" }}
                  placeholder="Nachricht"
                  data-rule="required"
                  data-msg="Please write something for us"
                ></textarea>
              </div>

              <div>
                <button
                  type="submit"
                  class="btn w-100 my-2 submit DSB1 my-4"
                  style={{ backgroundColor: "#E2C657" }}
                >
                  Senden
                </button>
              </div>
            </form>
            <div className="col-md-8"></div>
          </div>

          {/* <div className="col-md-1   "></div> */}

          <div className="col-md-6  d-flex justify-content-center ">
            <div className=" mt-5 p-3">
              {/* <div className="col-md-12 mb-4 ">
                <div style={{ color: "#ddd", fontWeight: "bold" }}>
                  <i
                    class="fa-solid fa-check pe-2"
                    style={{ color: "#E2C657" }}
                  ></i>
                  OPEN HOURS BY
                </div>
                <div className="text-white">
                  Monday – Saturday {Content[0]?.attributes.OpenHoursMonToSat}
                </div>
                <div className="text-white">
                  Sunday (office only) {Content[0]?.attributes.OpenHoursSunday}
                </div>
              </div> */}

              <div className="col-md-12 mb-0 p-3" style={{}}>
                <div
                  style={{
                    color: "#ddd",
                    fontWeight: "bold",
                    alignContent: "center",
                  }}
                  onClick={(e) => window.open(location, "_blank")}
                >
                  <img
                    src="../location-sharp.png"
                    style={{ paddingRight: "10px", color: "white" }}
                  />{" "}
                  ADRESSE
                </div>
                <div className="text-white">
                  {Content[0]?.attributes.MainAddress}
                </div>
              </div>
              {/* <div className="col-md-12 mb-4">
                <div style={{ color: "#ddd", fontWeight: "bold" }}>
                  {" "}
                  <img
                    src="../phone.png"
                    style={{ paddingRight: "8px", color: "white" }}
                  />{" "}
                  CALL US ON
                </div>

                <div className="text-white">
                  {Content[0]?.attributes.PhoneNo}
                </div>
              </div> */}
              <div className="col-md-12 p-3">
                <div style={{ color: "#ddd", fontWeight: "bold" }}>
                  {" "}
                  <img src="../Icon zocial-email.png" className="me-2" /> Senden
                  Sie uns eine E-Mail an
                </div>
                <div className="text-white">{Content[0]?.attributes.EMAIL}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ContactUs;
