/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable jsx-a11y/alt-text */

import "./App.css";

import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import Home from "./pages/Home/Home";
import Header from "./Header";
import Tranings from "./pages/Tranings/Tranings";
import Trainers from "./pages/Trainers/Trainers";
import Footer from "./Footer/Footer";
import Login from "./components/Login/login";
import Logins from "./pages/Login/Login";
import RealLogin from "./components/Login/Reallogin";
import TDetails from "./pages/TrainerDetail/TDetail";
import Booking from "./components/BookingPage/Booking";
import BookingDetail from "./components/BookingDetail/BookingDetail";
import BookingSchedule from "./components/ViewTranings/ViewTraning";
import BookingAdmin from "./components/BookingAdmin/BookingAdmin";
import Contactus from "./pages/Contactus/Contactus";
import ProfileDetail from "./components/BookingDetail/TrainerDetail";
import Fighters from "./pages/Fighters/Fighters";
import AllFighters from "./pages/Trainers/AlFighters";
import UberUnsComp from "./pages/UberUns/UberUns";
import FightersInner from "./components/AllTrainners/Fighters";
import React from "react";
import NotFound from "./pages/NotFound/notFound";

function App() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <Routes>
        <Route exact path="/" element={<Home />} />

        {/* <Route exact path="/Training" element={<Tranings />} /> */}
        <Route exact path="/Training&Priese" element={<Tranings />} />
        <Route exact path="/Trainers" element={<Trainers />} />
        <Route exact path="/Login" element={<Login />} />
        <Route exact path="/Logins" element={<RealLogin />} />
        <Route exact path="/Trainers/:name" element={<TDetails />} />
        <Route exact path="/Booking" element={<Booking />} />
        <Route exact path="/BookingDetail" element={<BookingDetail />} />
        <Route exact path="/ViewBooking" element={<BookingSchedule />} />
        <Route exact path="/Fighters/:name" element={<Fighters />} />
        <Route exact path="/AdminBooking" element={<BookingAdmin />} />
        <Route exact path="/ContactUs/:id" element={<Contactus />} />
        {/* <Route exact path="/ContactUs/:id" element={<Contactus />} /> */}
        <Route exact path="/ProfileDetail" element={<ProfileDetail />} />
        {/* <Route exact path="/Fighters" element={<AllFighters />} /> */}
        <Route exact path="/Uber-uns" element={<UberUnsComp />} />
        {/* Redirect to home if route is not found */}
        <Route path="*" element={<Navigate to="/" replace={true} />} />
      </Routes>
    </>
  );
}

export default App;
