import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { useAuth } from "../../Services/provideMain";
import bookingServices from './../../Services/services/booking';
import emailjs from '@emailjs/browser';
const Upcoming = () => {
  const [booking,setBooking]=React.useState([]);
  const {state}=useAuth();
  React.useEffect(()=>{
    bookingServices.getBooking(state.user._id).then((val)=>{
    
setBooking( val.Booking.filter(
  (val) =>
    val.status == 0 &&
    !moment(formatDate(val.Date))
      .startOf("day")
      .fromNow()
      .includes("ago")
));

    })
  },[])

  const updateBooking = async (id,val) => {
    Swal.fire({
      title: "Are You Sure You want to cancel it",
      showDenyButton: true,
   
                        
                       

                     
      confirmButtonColor: '#E2C657',
background:"#151414",
      confirmButtonText: "Ok",
    }).then(async (s) => {
      if (s.isConfirmed) {
        await bookingServices.updatebooking(id, {
          status: "2",
         
        });
        setBooking(booking.filter((val) => val._id != id));
        var templateParams = {
       
          email:val.UserId?.email,
          name:val.UserId?.firstName
      };
        emailjs.send('service_q74htgf', 'template_p9qs4fb', templateParams,"0Q1r-lMspJ69-UrFu")
        .then(function(response) {
           console.log('SUCCESS!', response.status, response.text);
        }, function(error) {
           console.log('FAILED...', error);
        });
        Swal.fire("Booking canceled");
      } else {
        Swal.fire({   title:"Booking not canceled",       
                        
                       

                     
        confirmButtonColor: '#E2C657',
  background:"#151414",
        confirmButtonText: "Ok",});
      }
    });
  };
  function formatDate(date) {
    let main = date.replace("th", "");
    var d = new Date(main),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }
  return (
    <ul class="list-group clipdow">
      <li class="list-group-item">
        {booking.length>0?booking.map((val,index)=>val.status==0&&
        <div class="row py-2 ">
          <div class="col-md-6 mb-5">
            <h5>{index+1} :Name:{val.Name}</h5>
           
            <h6 class="info">Date:{val.Date} . Time:{val.Time} . Price Per Hour:{val.Price} </h6>
          </div>
          <div class="col-md-6">
            <div class="row">
              <button type="button" class="btn btn-outline-dark actionbtn mb-2"  onClick={() => updateBooking(val._id,val)}>
                Cancel Booking
              </button>
              <button type="button" class="btn btn-outline-dark actionbtn"  onClick={() =>
                      Swal.fire({
                        title: "Detail",
                        
                       

                     
                        confirmButtonColor: '#E2C657',
                  background:"#151414",
                        confirmButtonText: "Ok",
                        html: `
                        <div style="font-weight:bold">Name:${val.Name}</div>
                       
                          <div style="font-weight:bold">Price:${val.Price}</div>
                          <div style="font-weight:bold">Building:${val.Building}</div>
                          <div style="font-weight:bold">Address:${val.Address}</div>
                          <div style="font-weight:bold">City:${val.City}</div>
                          <div style="font-weight:bold">State:${val.State}</div>
                          <div style="font-weight:bold">Message:${val.Message}</div>
                          
                         
                      `,

                        confirmButtonText: "Ok",
                      })
                    }>
                  View Booking Form
                </button>
            
            </div>
          </div>
          {index!=booking.length-1&&<hr className="mb-1"/>}
        </div>):<h1>No Booking</h1>}
      </li>
    </ul>
  );
};

export default Upcoming;