import React from "react";
import { Slide, Fade, Zoom, Roll, Bounce } from "react-awesome-reveal";
import useFetch from "./Services/Apihook";
import { useParams } from "react-router-dom";
import { Carousel } from "react-bootstrap";
const History = () => {
  const { loading, Content } = useFetch("home-who-we-ares?populate=*");
  const { Content: level } = useFetch(
    "home-tranning-level-headings?populate=*"
  );
  const { Content: history } = useFetch("home-history-of-muthais?populate=*");
  // console.log(val.attrib)
  React.useEffect(() => {
    Content.length > 0 &&
      Content.map((val) =>
        val?.attributes.images.data.map((imageName, index) =>
          console.log(imageName, "............imgName")
        )
      );
  }, []);
  Content.length > 0 &&
    Content.map((val) =>
      // val?.attributes.images.data.map((imageName, index) =>
      //console.log(val, "............val")
      // )
      val?.attributes.images.data.map((imageName, index) =>
        console.log(imageName?.attributes?.url, "............val")
      )
    );
  console.log(Content, "...............who are we");
  return (
    <div className="container my-md-5 pt-md-5 ">
      <div className="row">
        <div className="col-md-6 ps-md-0 ps-4">
          {Content.length > 0 &&
            Content.map((val) => (
              <>
                <Slide triggerOnce={true}>
                  <h4 className="title Pb">
                    {/* {val.attributes.Heading} */}
                    HISTORY
                  </h4>
                </Slide>
                <Slide triggerOnce={true}>
                  <p className="p-0">
                    {/* {val.attributes.Paragraph1} */}
                    Die Geschichte des Dado Gyms begann 1990, als Leonardo(Dado)
                    mit seinen Eltern nach Thailand in den Urlaub reiste. Dort
                    entdeckte Dado Muay Thai beim Besuch von Kämpfen im
                    berüchtigten Lumpini Stadion von Bangkok. Bei seiner
                    Rückkehr in die Schweiz, suchte er eine Schule um diesen
                    Kampfsport zu erlernen und wurde im “Nippon-Center Zürich“
                    fündig, wo Bobby Somsak Chaichan zusammen mit Natthapong
                    unterrichteten.
                  </p>
                  <p className="">
                    {/* {val.attributes.Paragraph2} */}
                    Ende 1994 musste das “Nippon-Center Zürich” Konkurs
                    anmelden, Dado der dann schon zu dieser Zeit sehr viel in
                    diesen Sport investiert hatte, verlor so die Möglichkeit mit
                    seinen Trainern und Kollegen zu trainieren. Somit beschloss
                    Dado alles auf eine Karte zu setzen und eröffnete selber mit
                    seinem ersparten Geld den “Tanikun Thaiboxclub Zürich” an
                    der Neugasse 85 in Zürich Kreis 4 (heutiges Riff Raff Kino)
                  </p>

                  <p> </p>
                </Slide>{" "}
              </>
            ))}
        </div>
        <div className="col-md-6 d-flex justify-content-md-end d-flex justify-content-center pt-md-1 py-5">
          {Content.length > 0 &&
            Content.map((val) => (
              <div style={{ position: "relative", width: "85%" }}>
                <Carousel>
                  {val.attributes.images.data.map((imageName, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                        //  src={`${imageurl}${val.attributes.BannerImage.data.attributes.url}`}
                        src={`https://dadocms.thecbt.live${imageName?.attributes?.url}`}
                        alt={`Slide ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            ))}
          {/* {Content.length > 0 &&
            Content.map((val) => (
              <div style={{ position: "relative", width: "85%" }}>
                <Carousel>
                  {val.attributes.images.data.map((imageName, index) => (
                    <Carousel.Item key={index}>
                      <img
                        className="d-block w-100"
                         // src={`${imageurl}${val.attributes.BannerImage.data.attributes.url}`}
                        //src={imageName?.attributes?.name}
                        alt={`Slide ${index + 1}`}
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            ))} */}
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      <div className="p-0">
        <Slide triggerOnce={true}>
          <p className="PN" style={{}}>
            {/* {val.attributes.Paragrph3} */}
            Er stellte seine eigenen Trainer Bobby Chaichan und Natthapong ein,
            was ihm ermöglichte, neben seinen Kollegen und neuen Schülern auch
            selber weiter zu trainieren und weiterhin regelmässig nach Thailand
            zu reisen um dort mit den besten Profis zu lernen und sich so im
            Muay Thai weiterzubilden.
          </p>
        </Slide>
        <Slide triggerOnce={true}>
          <p className="PN">
            {/* {val.attributes.Paragraph4} */}
            Finanziell gesehen war das sehr schwer, doch mit Idealismus, seinem
            eisernen Willen und der Liebe zu diesem Sport, erreichte er sein
            Ziel. Im Jahre 1998 übernahm Dado dann den “Thai Thai Boxclub
            Zürich” und führte auch diesen zusammen mit seinen beiden Trainern
            erfolgreich. Im Jahre 2000 wurde dann leider auch diese
            Trainingslokalität geschlossen. Bobby trainierte dann vermehrt in
            Winterthur im Wingthai Gym, Dado und Natthapong weiter zusammen im
            American Kickbox Center Zürich.
          </p>
        </Slide>
        <Slide triggerOnce={true}>
          <p className="PN">
            {/* {val.attributes.Paragraph5} */}
            2003 trennten sich dann Pong und Dado in guter Beziehung. Dado ging
            in Berufschulhaus zurück, wo er dazumals im Nippon-Center Zürich
            begonnen hatte und gründete das “Dado Gym Zürich“. Bobby Chaichan
            zog nach Thailand, wo er nun als erfolgreicher Geschäftsmann lebt
            und Pong eröffnete sein eigenes Gym in Zürich.
          </p>
        </Slide>
        <p>
          {/* {" "} */}
          <Slide triggerOnce={true}>
            <p className="PN">
              {/* {val.attributes.Paragraph6} */}
              Das Dado Gym erlangte schon nach kurzer Zeit dank den
              erfolgreichen Wettkämpfen rasch Anrekennung und die Anzahl der
              Mitglieder wuchs rasant an.
            </p>
          </Slide>
          <Slide triggerOnce={true}>
            <p className="PN">
              {/* {val.attributes.Paragraph7} */}
              Da aber der Hausabwart des Berufschulhauses Zürich unserem Sport
              nicht gut gesinnt war und vermehrt die Trainer und Mitglieder
              schickanierte, entschloss Dado 2008 noch einmal alles auf eine
              Karte zu setzen und mietete sich dann im Sportleistungscenter
              E.O.P ein. Das Gym lief dort dermassen gut, dass die Lokalität in
              kurzer Zeit zu klein wurde und eine neue Lokalität gesucht werden
              musste. Das Dado Gym zog dann weiter an den aktuelen Ort
              Flüelastrasse 12 Zürich Altstetten..
            </p>
          </Slide>
        </p>
      </div>
    </div>
  );
};

export default History;
