/* eslint-disable jsx-a11y/alt-text */
import classes from "./Footer.module.css";

import React from "react";
import { useNavigate } from "react-router-dom";
import useFetch from "../Services/Apihook";
// import Logo from "../../public/Logo.png";
import Logo from "../assets/Logo.png";
import Insta from "../assets/Instagram.png";
import Twitter from "../assets/twitter.png";
import Facebook from "../assets/fb.png";
import { useLocation } from "react-router-dom";
const Footer = () => {
  const location = useLocation();
  const handleClick = (e, id) => {
    console.log(id);
    if (location.pathname === "/") {
      e.preventDefault();
      const target = document.querySelector(id);
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    } else {
      if (id == "#home") navigate("/");
      if (id == "#ContactUs") navigate("/ContactUs");
    }
  };
  const [email, setemail] = React.useState();
  const { loading: load, Content } = useFetch("contact-us-details");

  const navigate = useNavigate();
  const move = (name) => {
    navigate(name);
  };
  return (
    <div className={classes.footer}>
      <div className="container w-20 footerContainer">
        <div className="row align-items-center">
          {/* ------------------Logo-------------------- */}
          <div className="col-md-3">
            <div className={`${classes.news} text-md-start text-center pt-3`}>
              <img src={`${Logo}`} width={"120vw"} />
            </div>
          </div>
          {/* ------------------Routes-------------------- */}
          <div className="col-md-3  ">
            <div className={`${classes.middle} row text-md-start text-center`}>
              <div className="col-12 TR1 ">
                <div
                  className={classes.text}
                  onClick={(e) => handleClick(e, "#home")}
                >
                  Home
                </div>
                <div className={classes.text} onClick={() => move("/Uber-uns")}>
                  Uber uns
                </div>
                {/* <div className={classes.text} onClick={() => move("/Training")}>
                  Trainings
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-md-3  ">
            <div className={`${classes.middle} row text-md-start text-center`}>
              <div className="col-12 TR1 ">
                <div
                  className={classes.text}
                  onClick={() => move("/Training&Priese")}
                >
                  Training & Preise
                </div>
                <div
                  className={classes.text}
                  onClick={(e) => handleClick(e, "#ContactUs")}
                >
                  Contact
                </div>
                {/* -----------------Fighters Section----------------- */}
                {/* <div className={classes.text} onClick={() => move("/Fighters")}>
                  Fighters
                </div> */}
                {/* -------------------------------------------------- */}
                {/* <div className={classes.text} onClick={() => move("/Training")}>
                  Trainings
                </div> */}
              </div>
            </div>
          </div>
          {/* <div className="col-md-1 "></div> */}

          {/* <div className="col-md-2 col-sm-6 ">
            <div className={`${classes.middle} row text-md-start text-center`}>
              <div className="col-12 TR1 ">
                <div
                  className={classes.text}
                  onClick={() => move("/ContactUs")}
                >
                  Contact
                </div>
                <div className={classes.text} onClick={() => move("/Logins")}>
                  Login
                </div>
              </div>
            </div>
          </div> */}
          {/* ------------------Address & location-------------------- */}
          <div className="col-md-3">
            <div className={`${classes.text} TR1 `}>
              <div className="d-flex justify-content-md-start justify-content-center pt-1">
                <img
                  src="../location-sharp.png"
                  style={{
                    paddingRight: "10px",
                    color: "white",
                    width: " 25px",
                    objectFit: "contain",
                  }}
                  className={classes.icon}
                  onClick={() =>
                    (window.location.href =
                      "https://www.google.com/maps/place/Dado+Gym/@47.3832814,8.4935447,17z/data=!3m1!4b1!4m6!3m5!1s0x47900bccc8c957e9:0x9d1bcb3d29b834e4!8m2!3d47.3832778!4d8.4961196!16s%2Fg%2F11g68z408f")
                  }
                  // className="img-fluid"
                />
                <span
                  onClick={() =>
                    (window.location.href =
                      "https://www.google.com/maps/place/Dado+Gym/@47.3832814,8.4935447,17z/data=!3m1!4b1!4m6!3m5!1s0x47900bccc8c957e9:0x9d1bcb3d29b834e4!8m2!3d47.3832778!4d8.4961196!16s%2Fg%2F11g68z408f")
                  }
                >
                  {Content[0]?.attributes.MainAddress}
                </span>
              </div>

              <div></div>
            </div>
            <div className="d-flex justify-content-md-start justify-content-center pt-1">
              <img
                src={`${Insta}`}
                className={classes.icon}
                onClick={() =>
                  (window.location.href = Content[0]?.attributes.instaLink)
                }
              />
              {/* <img
                src={`${Twitter}`}
                className={classes.icon}
                onClick={() =>
                  (window.location.href = Content[0]?.attributes.TwitterLink)
                }
              /> */}
              <img
                src={`${Facebook}`}
                className={classes.icon}
                onClick={() =>
                  (window.location.href = Content[0]?.attributes.fbLink)
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#000000" }}>
        {/* <hr style={{ backgroundColor: "#D5D5D5", marginTop: "0px" }}></hr> */}

        <div className="container">
          <div className="d-flex justify-content-center">
            <p className={`ft-text2 text-center m-0 ${classes.footers} DR1`}>
              Made by Digital Owner LLC
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
