import GenericServices from "./services/GenericService";

class Cms extends GenericServices {

  addContent = (url,data) => {console.log(data); return this.post(url, data)};

  getGetContent= (url) =>
    this.get(url);

}

let CmsService = new Cms();
export default CmsService;