import React from "react";
import { useNavigate } from "react-router-dom";
import classes from "./AllTrainers.module.css";
import bussnessServices from "./../../Services/services/bussnessuser";
const AllTrainers = () => {
  const [trainer, setTrainer] = React.useState([]);
  const navigate = useNavigate();
  const move = (name, value) => {
    navigate(name, { state: { info: value } });
  };
  React.useEffect(() => {
    bussnessServices.getBussness().then((val) => {
      console.log(val);
      setTrainer(val.bussness.filter((val) => val.userId.status == 1));
    });
  }, []);

  return (
    <div className={classes.bgcolor}>
      <div className="container">
        <div className="row py-5 d-flex  justify-content-center align-items-center">
          <h3
            className={`${classes.head2} fw-bold text-center text-white pb-3`}
          >
            {/* Trainers{" "} */}
          </h3>
          {trainer.length > 0 ? (
            trainer.map((val) => (
              <div
                className={`col-lg-3 col-md-4  mb-3 col-sm-6  col-6   text-center pb-3 shadow`}
                style={{ borderRadius: "4px" }}
              >
                <div
                  className="d-flex flex-column justify-content-center "
                  style={{ borderRadius: "4px" }}
                >
                  <img
                    width="100%"
                    className="manage"
                    src={`http://dadogymbackend.herokuapp.com/${val.profile}`}
                  />

                  <div style={{ backgroundColor: "#C9B45F" }}>
                    <div
                      className={`${classes.cardhead1} pt-2 DSB1 text-white`}
                    >
                      {val.userId?.firstName}
                    </div>
                    <div
                      className={`${classes.cardhead2} TR1 pb-3 text-white fw-bold`}
                    >
                      {val?.specialist}
                    </div>
                    <button
                      onClick={() =>
                        move(
                          "/Trainers/" + val.userId?.firstName + "-" + val._id,
                          val
                        )
                      }
                      className={`${classes.button} ${classes.button3} DR1`}
                    >
                      View Detail
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h1>No Trainer</h1>
          )}
        </div>
      </div>{" "}
    </div>
  );
};

export default AllTrainers;
