import React, { state, useRef } from "react";
import classes from "./Price.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../Services/provideMain";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import useFetch from "../../Services/Apihook";
const Prices = () => {
  const tableRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { state, auth } = useAuth();
  const { Content } = useFetch("traning-jahres-abo-prices");
  const { Content: con } = useFetch("traning-flex-abos");
  const { Content: personal } = useFetch("traning-personal-trainings");

  console.log(Content, "Content111");
  console.log(con, "Co 222");
  console.log(personal, "personal333");
  const loginfirst = () => {
    navigate("/Logins", {
      state: { from: location },
    });
  };

  const moves = (val) => {
    navigate("/ContactUs/ContactUs");
  };
  const scrollTableLeft = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
    }
  };

  const scrollTableRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft += 100; // Adjust the scroll amount as needed
    }
  };
  return (
    <div className="clip">
      <div className="container">
        <div className="row py-5">
          <h5
            className={`${classes.head2} fw-bold text-center text-white pb-3`}
          >
            Preise{" "}
          </h5>
          {/* <p className={`${classes.head} text-white mb-3 text-center `}>
            {" "}
            You can view all the packages for subscription
          </p> */}
          <div className="card shadow rounded mb-5">
            <h5 className="fw-bold text-center py-2">Jahres-Abo Muaythai </h5>
            <table
              class="table-responsive-md table-striped table-borderless "
              cellspacing="4"
            >
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="thc"
                    style={{ backgroundColor: "#232222" }}
                  >
                    (1 Jahr gebunden)
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Jahr
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    1/2 Jahr
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    1/4 Jahr
                  </th>
                </tr>
              </thead>
              <tbody>
                {Content.length > 0 &&
                  Content.map((val, index) => (
                    <tr
                      style={{
                        backgroundColor: index % 2 == 0 ? "#E6E6E6" : "#F3F3F3",
                      }}
                    >
                      {val.attributes.Titile === "UPGRADE ABO" ? (
                        <td>
                          <b>{val.attributes.Titile}</b>
                        </td>
                      ) : (
                        <td>{val.attributes.Titile}</td>
                      )}
                      <td className="text-center">
                        {val.attributes.PriceJahr}
                      </td>
                      <td className="text-center">
                        {val.attributes.Price1by2Jahr}
                      </td>
                      <td className="text-center">
                        {val.attributes.Price1by4Jahr}
                      </td>
                    </tr>
                  ))}
              </tbody>
              <h6
                className={`${classes.yellowTxt}`}
                style={{
                  color: "#E2C657",
                  fontWeight: "bold",
                  background: "#232222",
                }}
              >
                Einmalige Einschreibegebühr CHF30
              </h6>
            </table>
          </div>

          <div className="table-responsive-md card shadow rounded mb-5">
            <h5 className="fw-bold text-center py-2">MONTHLY-Abo Muaythai</h5>
            <div>
              <div className="table-btns d-flex justify-content-around d-md-none">
                <div className="left-btn-div">
                  <Button
                    id="scroll-left"
                    onClick={scrollTableLeft}
                    style={{
                      backgroundColor: "#232222",
                      border: "none",
                    }}
                  >
                    &lt;
                  </Button>
                </div>
                <div className="right-btn-div">
                  <Button
                    id="scroll-right"
                    onClick={scrollTableRight}
                    style={{
                      backgroundColor: "#232222",
                      border: "none",
                    }}
                  >
                    &gt;
                  </Button>
                </div>
              </div>
              <div className="table-responsive" ref={tableRef}>
                <table
                  class="table table-striped table-borderless"
                  cellspacing="4"
                >
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="thc"
                        style={{ backgroundColor: "#232222" }}
                      >
                        (Ungebunden)
                      </th>
                      <th
                        scope="col "
                        className="thc text-center"
                        style={{ backgroundColor: "#232222" }}
                      >
                        1 Monat
                      </th>
                      <th
                        scope="col "
                        className="thc text-center"
                        style={{ backgroundColor: "#232222" }}
                      >
                        3 Monaten
                      </th>
                      <th
                        scope="col "
                        className="thc text-center"
                        style={{ backgroundColor: "#232222" }}
                      >
                        6 Monaten
                      </th>
                      <th
                        scope="col "
                        className="thc text-center"
                        style={{ backgroundColor: "#232222" }}
                      >
                        1 Jahr
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {con.length > 0 &&
                      Content.map((val, index) => (
                        <tr
                          style={{
                            backgroundColor:
                              index % 2 == 0 ? "#E6E6E6" : "#F3F3F3",
                          }}
                        >
                          {val.attributes.Titile === "UPGRADE ABO" ? (
                            <td style={{ backgroundColor: "#E6E6E6" }}>
                              <b>{val.attributes.Titile}</b>
                            </td>
                          ) : (
                            <td style={{ backgroundColor: "#E6E6E6" }}>
                              {val.attributes.Titile}
                            </td>
                          )}
                          <td className="text-center">
                            {val.attributes.price_1_mnt}
                          </td>
                          <td className="text-center">
                            {val.attributes.price_3_mnt}
                          </td>
                          <td className="text-center">
                            {val.attributes.price_6_mnt}
                          </td>
                          <td className="text-center">
                            {val.attributes.PriceJahr}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                  <h6
                    className={`${classes.yellowTxt}`}
                    style={{
                      color: "#E2C657",
                      fontWeight: "bold",
                      background: "#232222",
                    }}
                  >
                    Einmalige Einschreibegebühr CHF30
                  </h6>
                </table>
              </div>
            </div>
          </div>
          <div className="card shadow rounded">
            <p
              className={` py-2 ${classes.Einzeleintritte}`}
              sx={{
                color: "black",
              }}
              style={{
                color: "black",
                fontWeight: "bold",
              }}
            >
              Einzeleintritte für Besucher Kosten 20- Pro Lektion
            </p>
            <h6 className="fw-bold py-2">
              Personal-Trainings Thaiboxen - Boxen - Kondition / Fitness
              Training - Selbstverteidigung
            </h6>
            {/* <h6 className="fw-bold text-center py-2">Thaiboxen</h6>
            <h6 className="fw-bold text-center py-2">Boxen</h6>
            <h6 className="fw-bold text-center py-2">
              Kondition / Fitness Training
            </h6> 
                    <h6 className="fw-bold text-center py-2">Selbstverteidigung</h6>*/}
            <table class="table table-striped table-borderless" cellspacing="4">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="thc"
                    style={{ backgroundColor: "#232222" }}
                  >
                    KURSKOSTEN
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Lektion 60 Min
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Lektion 30 Min
                  </th>
                </tr>
              </thead>
              <tbody>
                {personal.length > 0 &&
                  personal.map((val) => (
                    <tr style={{ backgroundColor: "#E6E6E6" }}>
                      <td style={{ backgroundColor: "#E6E6E6" }}>
                        {val.attributes.Title}
                      </td>
                      <td className="text-center">
                        {val.attributes.Lektion60Min}
                      </td>
                      <td className="text-center">
                        {val.attributes.Lektion30Min}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prices;
