import React from "react";
import classes from "./Detail.module.css";
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Detail = () => {
  const {state}=useLocation();
    const navigate=useNavigate()
    
  return (
    <div className="clipdown py-5">
      <div className={classes.detail}>
      <i class="fa-solid fa-arrow-left" style={{color:"white",fontSize:"20px",padding:"20px 0",cursor:"pointer",marginTop:"80px"}} onClick={()=>navigate(-1)}></i>

        <div className={classes.name} > Trainer: {state.info?.userId?.firstName}</div>
        <div className={classes.position}>{state.info.specialist}</div>
      </div>
      <div className="container-fluid p-4">
        <div className="row">
          <div className="col-sm-4 mt-1" >
             <LazyLoadImage
              effect="blur"
              width="95%"
              style={{border:"16px solid #E2C657",borderRadius:"2px"}}
         height="98%"
              src={`https://dadogymbackend.herokuapp.com/${state.info.profile}`}
            />
          </div>
          <div className="col-sm-8 text-md-start text-center">
            <div className="row">
              <div className="col-12">
               
                <div className={classes.head}>Fight Record:</div>
                <div className={classes.desc}>{state.info.fightRecord}</div>
                <div className={classes.head}>Titles:</div>
                <div className={classes.desc}>{state.info.titles}</div>
                <div className={classes.head}>Price per hour:</div>
                <div className={classes.desc}>{state.info.monthyFees}</div>
                <div className={classes.head}>Years of Experience:</div>
                <div className={classes.desc}>
                 {state.info.medal}
                </div>
               
                <div className={classes.head}>
                {state.info.about}
                </div>
                
              </div>
              <button
              onClick={()=>navigate("/Booking",{state:{info:state.info}})}
                    type="submit"
                    class="btn w-100 my-2 submit bg-yellow DSB1 my-3"
                  >
Book Now                  </button>
            </div>
            
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default Detail;
