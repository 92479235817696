import React from "react";
import classes from "./Header.module.css";
import { Slide, Fade, Zoom, Roll, Bounce } from "react-awesome-reveal";
import useFetch from "./Services/Apihook";
import { imageurl } from "./Services/urls";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Carousel } from "react-bootstrap";

import "./WhoWeAre.css";

const WhoWeAre = () => {
  const { loading, Content } = useFetch("home-who-we-ares?populate=*");
  const { Content: level } = useFetch(
    "home-tranning-level-headings?populate=*"
  );
  const { Content: history } = useFetch("home-history-of-muthais?populate=*");
  const [show, setShow] = React.useState(false);
  const [shows, setShows] = React.useState(false);
  const [arrow, setArrow] = React.useState(true);
  const handleClose = () => {
    setShows(false);
    setArrow(true);
  };
  const handleShow = () => {
    setShows(true);
    setArrow(false);
  };
  let param = useParams();
  React.useEffect(() => {
    if (param?.id) {
      setTimeout(() => {
        const element = document.getElementById(param?.id);

        element.scrollIntoView({ behavior: "smooth", block: "start" });
      }, 2300);
    }
  }, []);

  return (
    <>
      <div className="container my-5 pt-4 ">
        <div className="row ">
          <div className="col-md-6 ps-md-0 ps-4">
            {Content.length > 0 &&
              Content.map((val) => (
                <>
                  <Slide triggerOnce={true}>
                    <h4 className="title Pb">{val.attributes.Heading}</h4>
                  </Slide>
                  <Slide triggerOnce={true}>
                    <p className="PN">{/* {val.attributes.Paragraph1} */}</p>
                    <p className="PN">{val.attributes.Paragraph2}</p>

                    <p> {val.attributes.Paragrph3}</p>
                    <p> {val.attributes.Paragraph4}</p>
                    <p>
                      {" "}
                      <p> {val.attributes.Paragraph5}</p>
                      <p>
                        {" "}
                        <p> {val.attributes.Paragraph6} </p>
                      </p>
                    </p>
                  </Slide>{" "}
                </>
              ))}
          </div>
          {/* <div className="col-md-3"></div> */}
          <div
            className="col-md-6 d-flex justify-content-md-end d-flex justify-content-center align-item-center pt-md-0 pt-5"
            style={{
              display: "flex",
              // height: "300px",
              // width: "375px",
              justifyContent: "center",
              alignSelf: "center",
              alignItems: "center",
              py: "auto",
            }}
          >
            {/* {Content.length > 0 &&
              Content.map((val) => (
                <div style={{ position: "relative", width: "85%" }}>
                  <Carousel>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./1.png"
                        alt="First slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./2.png"
                        alt="Second slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./3.png"
                        alt="Third slide"
                      />
                    </Carousel.Item>
                    <Carousel.Item>
                      <img
                        className="d-block w-100"
                        src="./4.png"
                        alt="Third slide"
                      />
                    </Carousel.Item>
                  </Carousel>
                </div>
              ))} */}
            <img
              className="d-block w-100"
              src="./whoweareimg.png"
              alt="First slide"
              style={{
                width: "100%",
              }}
            />
          </div>

          <hr style={{ marginTop: "20px" }} />
        </div>
      </div>
    </>
  );
};

export default WhoWeAre;
