import { Tab, Tabs } from "react-bootstrap";
import Footer from "../../Footer/Footer";
import Header from "../../Header";
import useFetch from "../../Services/Apihook";
import Canceled from './Cancel';
import HistoryP from './History';
import Upcoming from './Upcomings';


const BookingSchedule = () => {
  const { loading:load, Content} = useFetch("contact-us-details");
  return (
    <>
     <Header Content={Content}/>
      <div class="py-5 mainbookings" style={{marginTop:"100px"}}>
        <div class="card booktopcard">
        <div class="card ">Booking Schedule</div>
 
              <div class="card p-3">
              {/* <div class="row">
            <div class="col">
              <input
                class="form-control form-control-lg form-control-borderless"
                type="search by User Name"
                placeholder="Search by User Name"
              />
            </div>
          </div> */}
                

                <Tabs
                  defaultActiveKey="upcoming"
                  id="uncontrolled-tab-example"
                  className="mb-3 mt-3"
                >
                  <Tab eventKey="upcoming" title="Upcoming">
                    <Upcoming />
                  </Tab>
                  <Tab eventKey="history" title="History">
                    <HistoryP />
                  </Tab>

                  <Tab eventKey="cancelled" title="Cancelled">
                    <Canceled />
                  </Tab>
                </Tabs>
              </div>
          
        </div>
      </div>
      <Footer/>
    </>
  );
};
export default BookingSchedule;