import React from 'react';
import TrainerBanner from '../../components/TrainersBanner/Banner';
import Detail from '../../components/ViewDetail/Detail';
import Footer from '../../Footer/Footer';
import Header from '../../Header';
import useFetch from '../../Services/Apihook';


const TDetails = () => {
  const { loading:load, Content} = useFetch("contact-us-details");
    return (
      <>
      
{/* <TrainerBanner/> */}
<Header Content={Content}/>
<Detail/>
<Footer/>
      </>
    );
  };
  export default TDetails;