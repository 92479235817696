import React from "react";
import useFetch from "../../Services/Apihook";
import { imageurl } from "../../Services/urls";

const TrainingHeadComponent = (props) => {
  const { loading, Content } = useFetch(
    `home-tranning-level-headings?populate=*`
  );

  console.log(Content, "Content");
  return loading ? (
    <h1
      style={{
        color: "white",
        height: "250px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      Loading...
    </h1>
  ) : (
    <div class="container pt-0 ">
      <div class="row mx-auto ">
        <div class="col-md-12">
          <div className="row">
            <div class="col-md-6 col-sm-12">
              <h5>{Content[props.trainingTab].attributes.Title}</h5>
              <p>{Content[props.trainingTab].attributes.Description}</p>
              <ul>
                {Content[
                  props.trainingTab
                ].attributes?.home_traning_sub_heads?.data.map((val) => (
                  <li>{val?.attributes?.ShortDescription}</li>
                ))}
              </ul>
            </div>
            <div class="col-md-6 col-sm-12">
              <img
                style={{
                  objectFit: "contain",
                }}
                src={`  ${imageurl}${
                  Content[props.trainingTab]?.attributes?.media_file?.data
                    ?.attributes?.url
                }`}
                width={"100%"}
                height={"350vh"}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrainingHeadComponent;
