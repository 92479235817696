import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { useAuth } from "../../Services/provideMain";
import { success } from "../../utilties/Messagehandler";
import UerServices from './../../Services/services/UserServices';
import { error } from './../../utilties/Messagehandler';
import { toast } from "react-toastify";

import Footer from "../../Footer/Footer";
import Header from "../../Header";
import useFetch from "../../Services/Apihook";
import Swal from "sweetalert2";
const RealLogin = () => {
  const toastId = React.useRef(null);
  const customId = "custom-id-yes";
  const { loading:load, Content} = useFetch("contact-us-details");
  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const data = await UerServices.login(values.email, values.password);
      Swal.fire({   title:data.message      , 
                        
                       

                     
        confirmButtonColor: '#E2C657',
  background:"#151414",
        confirmButtonText: "Ok",});

      setSubmitting(false);
      login(data.token);
    } catch (e) {
      console.log(e.error)
      if(! toast.isActive(toastId.current)) {
      toast.error("Username or Password is Incorrect", {
        position: "top-right",
        autoClose: 5000,
        toastId: customId,
        theme: "dark",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
   
    }
  };
  const [initial, setInitial] = React.useState({

    email: "",
    password: "",
 

  });
  let { login } = useAuth();
  const navigate=useNavigate();
  return (
    <>
     <Header Content={Content}/>
    <section class="text-center pb-3">
      <div
        class=" "
        style={{
          backgroundColor: "#232222",
          height: "350px",
        }}
      ></div>

      <div className="row justify-content-center align-items-center">
        <div
          class="card  shadow-lg col-md-4 col-10"
          style={{
            marginTop: "-240px",
            background: " hsla(0, 0%, 100%, 0.8)",
            backdropFilter: "blur(30px)",
          }}
        >
          <div class="card-body py-5  ">
            <div class="row d-flex justify-content-center">
              <div class="col-lg-11">
                <h2 class="fw-bold mb-5">Login now</h2>
                <Formik
                  enableReinitialize={true}
                  initialValues={initial}
                  validationSchema={Yup.object().shape({
            
                    email: Yup.string()
                      .email("Invalid email address format")
                      .required("Email is required"),
                    password: Yup.string()
               
                      .required("Password is required"),
          
                
                  
             
                  })}
                  onSubmit={handleFormSubmit}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <div class="row">
                        
                      
                     
                      </div>

                      <div class="form-outline mb-4 text-start">
                        <label
                          class="form-label BookLabels"
                          for="form3Example3"
                        >
                          Email address <span class="star">*</span>
                        </label>
                        <Field
                          name="email"
                          type="email"
                          id="form3Example3"
                          class="form-control inputTextfields"
                        />
                        <ErrorMessage
                          component="div"
                          name="email"
                          className="errorField"
                        />
                      </div>

                      <div class="form-outline mb-4 text-start">
                        <label
                          class="form-label BookLabels"
                          for="form3Example4"
                        >
                          Password <span class="star">*</span>
                        </label>
                        <Field
                          name="password"
                          type="password"
                          id="form3Example4"
                          
                          class="form-control inputTextfields"
                        />
                        <ErrorMessage
                          component="div"
                          name="password"
                          className="errorField"
                        />
                      </div>
               

                      <button
                        type="submit"
                        class="btn w-100 my-2 submit btn-dark DSB1"
                      >
                    Login
                      </button>
                      {/* <button
                        type="button"
                        class="btn w-100 my-2 submit btn-dark DSB1"
                        onClick={()=>navigate("/Login")}
                      >
                        Sign Up
                      </button> */}
                  
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Footer/>
    </>
  );
};

export default RealLogin;
