import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useAuth } from "../../Services/provideMain";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "formik";
import { Field } from "formik";
import bookingServices from "../../Services/services/booking";
import emailjs from "@emailjs/browser";
import bussnessServices from "../../Services/services/bussnessuser";

const BookingAdmin = () => {
  const { state } = useAuth();
  const { state: data } = useLocation();
  const navigate = useNavigate();
  const [Aclass, setClass] = React.useState([]);
  const [year, setYear] = React.useState([{}]);
  const [Cclass, setCClass] = React.useState("");
  const [classYears, setclassyears] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [init, setInit] = React.useState({
    email: state.user?.email,
    phoneno: state.user?.phoneNo,
    Class: "",
    Package: "",
    name: state.user?.name,
    Message: "",
    state: "",
    city: "",
    building: "",
    address: "",
  });
  const confirm = () => {
    Swal.fire({
      title: "Booking scheduled",
      confirmButtonColor: '#E2C657',
background:"#151414",
      confirmButtonText: "Ok",
      text: "Your will receive an email of joining data soon",

   
    });
  };
  const changePackage=(e)=>{
    setPrice(e.target.value.split("-")[0])
    setclassyears(e.target.value.split("-")[1])

  }
  const changeClass = (e) => {
    if(e.target.value!="Class"){
    setCClass(e.target.value);
    setPrice("");
    setYear(
      Aclass.filter((val) => val.className == e.target.value).map((val) => ({
        QuaterYear: val.QuaterYear,
        Year1: val.Year1,
        halfYear: val.halfYear,
      }))
    );
    }
  };
 
  const handleFormSubmit = (values) => {
    bookingServices
      .addAdminBooking({
        Price: price,
        Address: values.address,

        Building: values.building,
        Package:classYears,
        class: Cclass,
        City: values.city,
        State: values.state,
        Message: values.message,

        UserId: state.user._id,
      })
      .then((val) => {
        Swal.fire("Booking scheduled");
        navigate("/");
      });
  };
  React.useEffect(() => {
    bussnessServices.getService().then((val) => {
      setClass(val.userServices);
    });
  }, []);
  return (
    <div class="py-5 mainBookingContainer">
      <div class="card mx-auto ClientBookFormCard shadow-lg">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <Formik initialValues={init} onSubmit={handleFormSubmit}>
                {({ values, isSubmitting, validateForm, setTouched }) => (
                  <Form>
                    <h3>Fill out your details</h3>
                    <hr class="solid" />
                    <div class="row">
                      <div class="row">
                        <div class="col-md-6">
                          <label for="State" class="form-label BookLabels">
                            Choose Class<span class="star">*</span>
                          </label>
                          <select
                            class="form-select inputTextfields"
                            aria-label="Select Class"
                            as="select"
                            name="Class"
                            onChange={changeClass}
                            value={Cclass}
                          >
                            <option selected>Class</option>
                            {Aclass?.map((val) => (
                              <>
                                <option value={val.className}>
                                  {val.className}
                                </option>
                              </>
                            ))}
                          </select>
                        </div>
                        <div class="col-md-6">
                          <label for="State" class="form-label BookLabels">
                            Choose Package<span class="star">*</span>
                          </label>
                          <select
                            class="form-select inputTextfields"
                            aria-label="Select Class"
                            as="select"
                            name="Package"
                           
                            onChange={changePackage}
                          >
                            <option selected>Package</option>
                            {Object.entries(year[0]).map(([k, v]) => (
                              <option value={`${v}-${k}`}>{k}</option>
                            ))}
                          </select>
                        </div>
                        <div class="col-md-6">
                          <label for="Name" class="form-label BookLabels">
                            Name<span class="star">*</span>
                          </label>
                          <Field
                            type="text"
                            class="form-control inputTextfields"
                            id="Name"
                            name="name"
                            readOnly={true}
                          />
                        </div>
                        <div class="col-md-6">
                          <label for="Name" class="form-label BookLabels">
                            Price<span class="star">*</span>
                          </label>
                          <input
                            class="form-control inputTextfields"
                            aria-label="Select Class"
                            readOnly
                            value={price}
                            name="Price"
                          />
                        </div>
                      </div>

                      <div class="col-md-12">
                        <label for="Contact" class="form-label BookLabels">
                          Contact Number<span class="star">*</span>
                        </label>
                        <Field
                          type="tel"
                          readOnly={true}
                          class="form-control inputTextfields"
                          name="phoneno"
                          id="Contact"
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 NoteMsg"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <label for="Email" class="form-label BookLabels">
                          Email<span class="star">*</span>
                        </label>
                        <Field
                          type="email"
                          class="form-control inputTextfields"
                          id="Email"
                          name="email"
                          readOnly={true}
                        />
                        <label for="Address" class="form-label BookLabels">
                          Address<span class="star">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control inputTextfields"
                          id="address"
                          name="address"
                        />
                        <label for="City" class="form-label BookLabels">
                          City<span class="star">*</span>
                        </label>
                        <Field
                          name="city"
                          type="text"
                          class="form-control inputTextfields"
                          id="City"
                        />
                      </div>
                      <div class="col-md-6">
                        <label
                          for="BuildingFloor"
                          class="form-label BookLabels"
                        >
                          Building/Floor<span class="star">*</span>
                        </label>
                        <Field
                          name="building"
                          type="text"
                          class="form-control inputTextfields"
                          id="BuildingFloor"
                        />
                        <label for="State" class="form-label BookLabels">
                          State<span class="star">*</span>
                        </label>
                        <Field
                          as="select"
                          name="state"
                          class="form-select inputTextfields"
                          aria-label="Select State"
                        >
                          <option>State</option>
                          <option value="Punjab">Punjab</option>
                          <option value="Sindh">Sindh</option>
                          <option value="KPK">KPK</option>
                          <option value="Balochistan">Balochistan</option>
                        </Field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <label for="Message" class="form-label BookLabels">
                          Message<span class="star">*</span>
                        </label>
                        <Field
                          name="message"
                          type="text"
                          class="form-control inputTextfields"
                          id="Message"
                        />
                      </div>
                    </div>
                    <button type="submit" class="btn paybtn btn-success batn">
                      Book Now
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
            <div class="col-md-4">
              <h3>Booking Summary</h3>
              <hr class="solid" />
              <div class="row Paymentrow">
                <div class="col-md-6">
                  <h5 class="floatstart">
                    Trainer Name :<b>To be Decided</b>
                  </h5>
                </div>
                <div class="col-md-6">
                  <h5 class="floatend">...</h5>
                </div>
              </div>

              <hr class="solid" />
              <h4>Payment Details</h4>
              <div class="row Paymentrow">
                <div class="col-md-6">
                  <h5 class="floatstart">
                    Total: <b>{price}</b>
                  </h5>
                </div>
                <div class="col-md-6">
                  <h5 class="floatend">...</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingAdmin;
