import React from 'react';
import useFetch from './Services/Apihook';
import { imageurl } from './Services/urls';
const Clients = () => {
  const {  Content } = useFetch("home-dado-gym-videos?populate=*");
    return (
      Content.map((val)=>
        <div >
          
        <div className="">
          <div className="row g-0 ">
            <div className="col-md-2 pt-5 d-md-block d-none">
              {/* <img src="VIDEO.png" className="w-25" /> */}
            </div>
            <div className="col-md-8 backgroundvideo text-end ">
       
              <video controls
           poster="./Thumbnail.png"
                className="videowidth"
                style={{ marginTop: "-90px" ,cursor:"pointer"}}
              >
                 <source    src={`${imageurl}${val.attributes.GymVideo.data.attributes.url}`} />
                 </video>
            </div>
            <div className="col-md-2 pt-5 d-md-block d-none">
              {/* <img src="VIDEO.png" className="w-25" /> */}
            </div>
          </div>
        </div>
      </div>
      )
      );
}
 
export default Clients;