import React from "react";
import useFetch from "../../Services/Apihook";
import { imageurl } from "../../Services/urls";
import classes from "./TrainerBanner.module.css";
import ProgressiveImage from "react-progressive-graceful-image";

const TrainerBanner = ({ Content, loadState, loader }) => {
  // React.useEffect(() => {
  //   if (!Content[0].attributes.bgImage.data.attributes.url && loader == false) {
  //     loader(true);
  //   }
  // }, [Content]);

  return Content.map(
    (val) => (
      <ProgressiveImage
        src={`${imageurl}${val.attributes.bgImage.data.attributes.url}`}
        style={{
          objectFit: "cover",
          height: "100vh",
        }}
        placeholder=""
      >
        {(src, loading) => {
          return loading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                background: "black",
                height: "100vh",
                position: "absolute",
                top: "0",
                zIndex: "12000",
                left: "0",
              }}
              className="check"
              role="status"
            ></div>
          ) : (
            <div style={{ position: "relative" }}>
              <img
                id="fighter-img"
                class="responsives"
                style={{
                  width: "100%",
                  height: "100vh",
                  objectFit: "cover",
                  marginTop: "8vh",
                  filter: "brightness(0.7)",
                }}
                src={src}
                alt="sea beach"
              />
              <div
                style={{
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50% )",
                  color: "white",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                }}
              >
                {/* -----------Show in mobile only-------------
                <div className={`${classes.mblTitle} d-md-block d-none`}>
                  {" "}
                  <h3 className="Pb text-center">{val.attributes.Title}</h3>
                </div>
                {/*-----------Show in desktop only------------- }
                <div className={classes.DskTitle}>
                  {" "}
                  <h3 className="Pb text-center">{val.attributes.Title}</h3>
                </div> */}

                {/* <div>
                  {" "}
                  <h3 className="Pb text-center">{val.attributes.Title}</h3>
                </div> */}
                <div
                  style={{
                    width: "100%",
                    margin: "auto",
                    // backgroundColor: "rgb(40 35 42 /  85%)",
                  }}
                  className={classes.mbl}
                >
                  <h3 className="Pb text-center">{val.attributes.Title}</h3>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      backgroundColor: "rgb(40 35 42 /  85%)",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      style={{
                        fontWeight: "medium",
                        // color: "#FFD700",
                        color: "white",
                        marginLeft: "7px",
                        margin: "auto",
                      }}
                      className="text-center d-sm-block d-none"
                    >
                      {val.attributes.Description}
                    </p>
                  </div>
                </div>
              </div>
              {/* <div style={{position:"absolute",top:'50%',left:"10%"}}>
          <p
            className="d-sm-block d-none"
            style={{
              width: "400px",
              fontWeight: "medium",
              marginLeft: "7px",
            }}
          >
            {val.attributes.Description}
          </p>
          <div className="button12 d-md-block d-none">
            <button
              onClick={() => navigate("/Training")}
              type="submit"
              style={{ height: "40%", marginRight: "80px" }}
              class="btn    submit text-white border arrow DSB1"
            >
              JOIN US NOW
            </button>
          </div>
          <div className="d-md-none d-block text-center ">
            <button
              type="submit"
              style={{ height: "70%", marginRight: "80px" }}
              class="btn   submit text-white border arrow DSB1"
            >
              JOIN US NOW
            </button>
          </div>
          </div> */}
            </div>
          );
        }}
      </ProgressiveImage>
    )
    //   <div className={classes.bgpic} style={{
    //     height: '88vh',
    // backgroundSize:' 100% 100%',
    // display: 'flex',
    // justifyContent:' start',
    // alignItems: 'center!important',

    //     backgroundSize: "cover",
    //     backgroundImage: `url(${imageurl}${

    //          val.attributes.bgImage.data.attributes.url

    //     })`,
    //   }}>
    //     <div style={{ width: "35%", margin: "auto" }} className={classes.mbl}>
    //       <h1 className="Pb text-center">{  val.attributes.Title}</h1>

    //       <p className="text-center">
    //       {  val.attributes.Description}
    //       </p>

    //     </div>
    //   </div>
  );
};

export default TrainerBanner;
