import React from "react";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import ImageUploading from "react-images-uploading";
import { useNavigate } from "react-router-dom";
import { Tab, Tabs } from "react-bootstrap";
import { success } from "../../utilties/Messagehandler";
import { error } from './../../utilties/Messagehandler';
import UerServices from './../../Services/services/UserServices';
import Footer from "../../Footer/Footer";
import Header from "../../Header";
import useFetch from "../../Services/Apihook";
import Swal from "sweetalert2";
const Login = () => {
  const { loading:load, Content} = useFetch("contact-us-details");
  const handleFormSubmit = async (values) => {
    try {
      console.log(2);
      const data = await UerServices.Register(
        values.name,
        values.LastName,
        values.email,
        values.password,
        values.confirmPassword,
        values.phoneNo
      );
  
 
      Swal.fire({   title:data.message      , 
                        
                       

                     
      confirmButtonColor: '#E2C657',
background:"#151414",
      confirmButtonText: "Ok",});
      navigate("/Logins")
    } catch (e) {
      
      error(e.error);
    }
  };

  const handleTrainer = async (values) => {
    try {
      console.log(2);
      const data = await UerServices.Register(
        values.name,
        values.LastName,
        values.email,
        values.password,
        values.confirmPassword,
        values.phoneNo,
        "trainer"
      );
  
      success(data.message);
      navigate("/Logins")
    } catch (e) {
      
      error(e.error);
    }
  };
  
  const navigate = useNavigate();
  const handleform = async (values) => {};
  const [initial, setInitial] = React.useState({
    name: "",
    email: "",
    password: "",
    LastName: "",
    confirmPassword: "",
phoneNo:""

  });
  const [ini, setIni] = React.useState({
    name: "",
    email: "",
    password: "",
    LastName: "",
    confirmPassword: "",
    price: 0,
    bussnessCategory: "",
  });

  
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  return (
    <>
    <Header Content={Content}/>
    <div class="text-center">
      <div
        class="container-fluid "
        style={{
          backgroundColor: "#232222",
          height: "600px",
        }}
      ></div>

      <div className="row justify-content-center align-items-center">
        <div
          class="card  shadow-lg col-md-4 col-10"
          style={{
            marginTop: "-400px",
            background: " hsla(0, 0%, 100%, 0.8)",
            backdropFilter: "blur(30px)",
            overflowX: "hidden",
          }}
        >
          <Tabs
            defaultActiveKey="home"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="home" title="User">
              <div class="card-body py-5  ">
                <div class="row d-flex justify-content-center">
                  <div class="col-lg-11">
                    <h2 class="fw-bold mb-5">User Sign up </h2>
                    <Formik
                      enableReinitialize={true}
                      initialValues={initial}
                      validationSchema={Yup.object().shape({
                        name: Yup.string()
                          .min(3, "name must be 3 characters at minimum")
                          .max(10, "name must be 11 characters at max")
                          .required("Name is Required"),
                        email: Yup.string()
                          .email("Invalid email address format")
                          .required("Email is required"),
                        password: Yup.string()
                          .min(3, "Password must be 3 characters at minimum")
                          .required("Password is required"),
                        LastName: Yup.string()
                          .min(3, "LastName must be 3 characters at minimum")
                          .required("LastName is required"),
                        confirmPassword: Yup.string().test(
                          "passwords-match",
                          "Passwords must match",
                          function (value) {
                            return this.parent.password === value;
                          }
                        ),
                        phoneNo: Yup.string()
                        .min(11, "PhoneNo must be 11 characters at minimum")
                        .required("PhoneNo is required"),
                       
                      })}
                      onSubmit={handleFormSubmit}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div class="row">
                            <div class="col-md-12 mb-4">
                              <div class="form-outline text-start">
                                <label
                                  class="form-label BookLabels "
                                  for="form3Example1"
                                >
                                  First name <span class="star">*</span>
                                </label>
                                <Field
                                  name="name"
                                  type="text"
                                  id="form3Example1"
                                  class="form-control inputTextfields"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="errorField"
                                />
                              </div>
                            </div>
                            <div class="col-md-12 mb-4">
                              <div class="form-outline text-start">
                                <label
                                  class="form-label BookLabels"
                                  for="form3Example2"
                                >
                                  Last name <span class="star">*</span>
                                </label>
                                <Field
                                  name="LastName"
                                  id="form3Example2"
                                  class="form-control inputTextfields"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="LastName"
                                  className="errorField"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example3"
                            >
                             phoneNo <span class="star">*</span>
                            </label>
                            <Field
                              name="phoneNo"
                              type="text"
                              id="form3Example3"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="phoneNo"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example3"
                            >
                              Email address <span class="star">*</span>
                            </label>
                            <Field
                              name="email"
                              type="email"
                              id="form3Example3"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example4"
                            >
                              Password <span class="star">*</span>
                            </label>
                            <Field
                              name="password"
                              type="password"
                              id="form3Example4"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="password"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example4"
                            >
                              Confirm Password <span class="star">*</span>
                            </label>
                            <Field
                              name="confirmPassword"
                              type="password"
                              id="form3Example4"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="confirmPassword"
                              className="errorField"
                            />
                          </div>
                        

                          <button
                            type="submit"
                            class="btn w-100 my-2 submit btn-dark DSB1"
                          >
                            Sign up
                          </button>
                          <button
                            type="button"
                            class="btn w-100 my-2 submit btn-dark DSB1"
                            onClick={() => navigate("/Logins")}
                          >
                            Login
                          </button>
                          <div class="text-center">
                            <p>or sign up with:</p>
                            <button
                              type="button"
                              class="btn btn-link btn-floating mx-1"
                            >
                              <i class="fab fa-facebook-f"></i>
                            </button>

                            <button
                              type="button"
                              class="btn btn-link btn-floating mx-1"
                            >
                              <i class="fab fa-google"></i>
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Trainer" title="Trainer">
              <div class="card-body py-5  ">
                <div class="row d-flex justify-content-center">
                  <div class="col-lg-11">
                    <h2 class="fw-bold mb-5">Trainer Sign up </h2>
                    <Formik
                      enableReinitialize={true}
                      initialValues={initial}
                      validationSchema={Yup.object().shape({
                        name: Yup.string()
                          .min(3, "name must be 3 characters at minimum")
                          .max(10, "name must be 11 characters at max")
                          .required("Name is Required"),
                        email: Yup.string()
                          .email("Invalid email address format")
                          .required("Email is required"),
                        password: Yup.string()
                          .min(3, "Password must be 3 characters at minimum")
                          .required("Password is required"),
                        LastName: Yup.string()
                          .min(3, "LastName must be 3 characters at minimum")
                          .required("LastName is required"),
                        confirmPassword: Yup.string().test(
                          "passwords-match",
                          "Passwords must match",
                          function (value) {
                            return this.parent.password === value;
                          }
                        ),
                        phoneNo: Yup.string()
                        .min(11, "PhoneNo must be 11 characters at minimum")
                        .required("PhoneNo is required"),
                       
                      })}
                      onSubmit={handleTrainer}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div class="row">
                            <div class="col-md-12 mb-4">
                              <div class="form-outline text-start">
                                <label
                                  class="form-label BookLabels "
                                  for="form3Example1"
                                >
                                  First name <span class="star">*</span>
                                </label>
                                <Field
                                  name="name"
                                  type="text"
                                  id="form3Example1"
                                  class="form-control inputTextfields"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="errorField"
                                />
                              </div>
                            </div>
                            <div class="col-md-12 mb-4">
                              <div class="form-outline text-start">
                                <label
                                  class="form-label BookLabels"
                                  for="form3Example2"
                                >
                                  Last name <span class="star">*</span>
                                </label>
                                <Field
                                  name="LastName"
                                  id="form3Example2"
                                  class="form-control inputTextfields"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="LastName"
                                  className="errorField"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example3"
                            >
                             phoneNo <span class="star">*</span>
                            </label>
                            <Field
                              name="phoneNo"
                              type="text"
                              id="form3Example3"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="phoneNo"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example3"
                            >
                              Email address <span class="star">*</span>
                            </label>
                            <Field
                              name="email"
                              type="email"
                              id="form3Example3"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example4"
                            >
                              Password <span class="star">*</span>
                            </label>
                            <Field
                              name="password"
                              type="password"
                              id="form3Example4"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="password"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example4"
                            >
                              Confirm Password <span class="star">*</span>
                            </label>
                            <Field
                              name="confirmPassword"
                              type="password"
                              id="form3Example4"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="confirmPassword"
                              className="errorField"
                            />
                          </div>
                        

                          <button
                            type="submit"
                            class="btn w-100 my-2 submit btn-dark DSB1"
                          >
                            Sign up
                          </button>
                          <button
                            type="button"
                            class="btn w-100 my-2 submit btn-dark DSB1"
                            onClick={() => navigate("/Logins")}
                          >
                            Login
                          </button>
                        
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Tab>
            {/* <Tab eventKey="profile" title="Trainer">
              <div class="card-body py-5  ">
                <div class="row d-flex justify-content-center">
                  <div class="col-lg-11">
                    <h2 class="fw-bold mb-5">Trainer Sign up </h2>
                    <Formik
                      enableReinitialize={true}
                      initialValues={ini}
                      validationSchema={Yup.object().shape({
                        name: Yup.string()
                          .min(3, "name must be 3 characters at minimum")
                          .max(10, "name must be 11 characters at max")
                          .required("Name is Required"),
                        price: Yup.number().min(
                          10,
                          "price must be at minimum length 10"
                        ),

                        email: Yup.string()
                          .email("Invalid email address format")
                          .required("Email is required"),
                        password: Yup.string()
                          .min(3, "Password must be 3 characters at minimum")
                          .required("Password is required"),
                        LastName: Yup.number()
                          .min(3, "PhoneNo must be 11 characters at minimum")
                          .required("PhoneNo is required"),
                        confirmPassword: Yup.string().test(
                          "passwords-match",
                          "Passwords must match",
                          function (value) {
                            return this.parent.password === value;
                          }
                        ),
                        bussnessStatus: Yup.string().required(
                          "Bussness Status is Required"
                        ),
                        account: Yup.string().required(
                          "Social Link is Required"
                        ),
                        about: Yup.string()
                          .min(25, "Please enter atleast 25 charcater")
                          .required("About is required"),
                        address: Yup.string().required("address is required"),

                        bussnessName: Yup.string().required(
                          "bussnesss Name is Required"
                        ),
                        bussnessCategory: Yup.string().required(
                          "Category Name is Required"
                        ),
                      })}
                      onSubmit={handleform}
                    >
                      {({ isSubmitting }) => (
                        <Form>
                          <div class="row">
                            <div class="col-md-12 mb-4">
                              <div class="form-outline text-start">
                                <label
                                  class="form-label BookLabels "
                                  for="form3Example1"
                                >
                                  First name <span class="star">*</span>
                                </label>
                                <Field
                                  name="name"
                                  type="text"
                                  id="form3Example1"
                                  class="form-control inputTextfields"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="name"
                                  className="errorField"
                                />
                              </div>
                            </div>
                            <div class="col-md-12 mb-4">
                              <div class="form-outline text-start">
                                <label
                                  class="form-label BookLabels"
                                  for="form3Example2"
                                >
                                  Name <span class="star">*</span>
                                </label>
                                <Field
                                  name="LastName"
                                  id="form3Example2"
                                  class="form-control inputTextfields"
                                />
                                <ErrorMessage
                                  component="div"
                                  name="LastName"
                                  className="errorField"
                                />
                              </div>
                            </div>
                          </div>

                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example3"
                            >
                              Email address <span class="star">*</span>
                            </label>
                            <Field
                              name="email"
                              type="email"
                              id="form3Example3"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="email"
                              className="errorField"
                            />
                          </div>

                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example4"
                            >
                              Password <span class="star">*</span>
                            </label>
                            <Field
                              name="password"
                              type="password"
                              id="form3Example4"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="password"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example4"
                            >
                              Confirm Password <span class="star">*</span>
                            </label>
                            <Field
                              name="confirmPassword"
                              type="password"
                              id="form3Example4"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="confirmPassword"
                              className="errorField"
                            />
                          </div>
                          <div class="form-outline mb-4 text-start">
                            <label
                              class="form-label BookLabels"
                              for="form3Example4"
                            >
                              Price <span class="star">*</span>
                            </label>
                            <Field
                              name="price"
                              type="number"
                              id="form3Example4"
                              class="form-control inputTextfields"
                            />
                            <ErrorMessage
                              component="div"
                              name="price"
                              className="errorField"
                            />
                          </div>

                          <ImageUploading
                       
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                          >
                            {({
                              imageList,
                              onImageUpload,
                              onImageRemoveAll,
                              onImageUpdate,
                              onImageRemove,
                              isDragging,
                              dragProps,
                            }) => (
                              // write your building UI
                              <div className="upload__image-wrapper">
                                <button
                                   type="button"
                                  className="btn btn-info"
                                  style={
                                    isDragging ? { color: "red" } : undefined
                                  }
                                  onClick={onImageUpload}
                                  {...dragProps}
                                >
                                  Click or Drop here
                                </button>
                                &nbsp;
                                <button
                                   type="button"
                                  className="btn btn-info"
                                  onClick={onImageRemoveAll}
                                >
                                  Remove all images
                                </button>
                                {imageList.map((image, index) => (
                                  <div key={index} className="image-item">
                                    <img
                                      src={image["data_url"]}
                                      alt=""
                                      width="100"
                                    />
                                    <div className="image-item__btn-wrapper">
                                      <button
                                      type="button"
                                        className="btn"
                                        onClick={() => onImageUpdate(index)}
                                      >
                                        Update
                                      </button>
                                      <button
                                         type="button"
                                        className="btn"
                                        onClick={() => onImageRemove(index)}
                                      >
                                        Remove
                                      </button>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </ImageUploading>

                          <button
                            type="submit"
                            class="btn w-100 my-2 submit btn-dark DSB1"
                          >
                            Sign up
                          </button>
                          <button
                            type="button"
                            class="btn w-100 my-2 submit btn-dark DSB1"
                            onClick={() => navigate("/Logins")}
                          >
                            Login
                          </button>
                          <div class="text-center">
                            <p>or sign up with:</p>
                            <button
                              type="button"
                              class="btn btn-link btn-floating mx-1"
                            >
                              <i class="fab fa-facebook-f"></i>
                            </button>

                            <button
                              type="button"
                              class="btn btn-link btn-floating mx-1"
                            >
                              <i class="fab fa-google"></i>
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </div>
              </div>
            </Tab> */}
          </Tabs>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default Login;
