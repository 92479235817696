import React from "react";
import CmsService from "./CMS";

const useFetch = (url) => {
  const [Content, setContent] = React.useState([]);
  const [loading, setloading] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      apicall();
    }, 500);
  }, [url]);

  const apicall = async () => {
    try {
      setloading(true);
      const contents = await CmsService.getGetContent(url);

      setContent(contents);
      setloading(false);
    } catch (e) {
      setloading(false);
    }
  };
  return { loading, Content };
};

export default useFetch;
