import React, { useRef } from "react";
import classes from "./Header.module.css";
import { Nav, Navbar, Container, Button } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { useAuth } from "./Services/provideMain";
import useFetch from "./Services/Apihook";
import { Carousel } from "react-bootstrap";
import CmsService from "./Services/CMS";
import { useEffect } from "react";
import "./Header.css";
import { useLocation } from "react-router-dom";
import HeaderStyles from "./Header.module.css";
const Header = ({ Content }) => {
  const navigate = useNavigate();
  const myIdRef = useRef();
  const myIdRef1 = useRef();
  console.log(myIdRef.current);
  const [content1, setContent] = React.useState([]);
  const [menubtnState, setMenuBtnState] = React.useState("unClicked");
  useEffect(
    () => {
      console.log("useEffect");
      setMenuBtnState("unClicked");
      console.log(menubtnState);
      api();
    },
    [],
    [menubtnState]
  );

  const api = async () => {
    try {
      const response = await CmsService.getGetContent("news-banners");
      console.log(response);
      setContent(response);
    } catch (error) {
      console.error(error);
    }
    // const contents = await CmsService.getGetContent("news-banners");
    // setContent(contents);
    // console.log(contents[0]?.attributes.news_text, "header data");
  };
  const move = (name) => {
    navigate(name);
  };
  let { state, handleLogout } = useAuth();
  let activeStyle = {
    fontWeight: "bold",
  };
  const handleCollapse = () => {
    console.log("handleCollapse");
    var nav = document.getElementById("navbarNav");
    var btn = document.getElementById("navbarBtn");
    nav.classList.remove("show");
    btn.classList.add("collapsed");
  };
  const location = useLocation();
  const currentPage = location.pathname;
  console.log(content1[0]?.attributes, "data12334");
  const handleClick = (e, id) => {
    console.log(id);
    if (location.pathname === "/") {
      e.preventDefault();
      const target = document.querySelector(id);
      target.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
      handleMenuBtnClick();
    } else {
      if (id == "#home") navigate("/");
      if (id == "#ContactUs") navigate("/ContactUs");
      handleMenuBtnClick();
    }
  };
  const [isOpen, setIsOpen] = React.useState(false);
  const [isOpenn, setIsOpenn] = React.useState(false);
  const handleMenuBtnClick = (e) => {
    setIsOpen(!isOpen);
    menubtnState == "unClicked"
      ? setMenuBtnState("clicked")
      : setMenuBtnState("unClicked");
    // console.log("menuBtnState", menubtnState);
    const button = document.querySelector(".navbar-mbl-btn");
    const btnIcon = button.querySelector(".btn-icon");
    btnIcon.classList.toggle("active");
    if (menubtnState == "unClicked") {
      console.log(myIdRef.current, "myIdRefmyIdRefmyIdRefmyIdRef");
      console.log("menu-btn-state: ", menubtnState);
      var element = document.getElementById("myElement");
      var element1 = document.getElementById("myElement1");
      console.log(element);
      element.style.display = "none";
      element1.style.display = "none";
    } else {
      console.log("menu-btn-state: ", menubtnState);
      var element = document.getElementById("myElement");
      var element1 = document.getElementById("myElement1");
      element.style.display = "block";
      element1.style.display = "block";
    }
  };

  // useEffect(() => {
  //   if(Location.pathname="")
  //   handleMenuBtnClick();
  // }, [location]);

  const handleMobNavs = (e, pgNo) => {
    if (pgNo == 0) {
      // navigate("/");
      // handleMenuBtnClick();
      window.location.href = "/";
    } else if (pgNo == 1) {
      // handleMenuBtnClick();
      window.location.href = "/Uber-uns";
      // navigate("/Uber-uns");
    } else if (pgNo == 2) {
      // handleMenuBtnClick();
      window.location.href = "/Training&Priese";
      // navigate("/Training");
    }
    // else if (pgNo == 3) {
    //   // handleMenuBtnClick();
    //   window.location.href = "/Fighters";
    //   // navigate("/Fighters");
    // }
    else if (pgNo == 4) {
      // handleMenuBtnClick();
      handleClick(e, "#ContactUs");
    }
    // handleMenuBtnClick();

    // if (location.pathname != "/" && !open) {
    //   handleMenuBtnClick();
    // }
  };
  return (
    <div>
      <div
        className={`container-fluid d-lg-block d-none position-fixed fixed-top`}
      >
        <div className="row">
          <div className={`${classes.logo} col-xl-2  col-md-2 `}>
            <div
              style={{ width: "110px", marginBottom: "0", marginTop: "0px" }}
              className={classes.size}
            >
              <img src="../Logo.png" className={`${classes.logosize} `} />
            </div>
          </div>
          <div
            className={`col-xl-10  col-md-10 ${classes.heads} ${classes.header2} `}
          >
            <div className="row">
              <div className={`${classes.header1} col-md-12`}>
                <div className="  align-items-center py-2 PN">
                  <div className="">
                    {/* <div className="pe-5 ">
                      <img src="../phone.png" className="me-2"></img>   {Content[0]?.attributes.PhoneNo}
                    </div> */}
                    <div className=" " width="100%">
                      <Carousel
                        width="100%"
                        controls={false}
                        indicators={false}
                        style={{ transitionDuration: "20s" }}
                        className={HeaderStyles.carousel}
                      >
                        <Carousel.Item
                          interval={0}
                          className={HeaderStyles.carouselItem}
                        >
                          <div height="100px" width="100%">
                            {content1[0]?.attributes?.news_text}
                          </div>
                        </Carousel.Item>

                        <Carousel.Item
                          interval={0}
                          className={HeaderStyles.carouselItem}
                        >
                          <div height="100px" width="100%">
                            {content1[0]?.attributes?.news_text}
                          </div>
                        </Carousel.Item>
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`${classes.header2}  col-md-12 `}>
                <div className="row s justify-content-end align-items-center">
                  <div className="col-lg-12 ">
                    <div className="row PN d-flex justify-content-between align-items-center p-2">
                      <div className="col-lg-2 d-flex justify-content-center">
                        <NavLink
                          to="/"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                          className={`${classes.white} normal1`}
                          // onClick={() => move("/")}
                          onClick={(e) => handleClick(e, "#home")}
                        >
                          Home
                        </NavLink>
                      </div>
                      <div className="col-lg-2 d-flex justify-content-center">
                        <NavLink
                          to="/Uber-uns"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                          className={`${classes.white} normal1`}
                          onClick={() => move("/Uber-uns")}
                        >
                          Uber Uns
                        </NavLink>
                      </div>
                      <div className="col-lg-2 d-flex justify-content-center">
                        {state.user?.role != "trainer" ? (
                          <NavLink
                            style={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }
                            className={`${classes.white} normal1`}
                            to="/Training&Priese"
                          >
                            Training & Preise
                          </NavLink>
                        ) : (
                          <NavLink
                            tyle={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }
                            className={`${classes.white} normal1`}
                            to="/ProfileDetail"
                          >
                            Profile
                          </NavLink>
                        )}
                      </div>
                      {/* <div className="col-lg-2">
                        {state.user?.role != "trainer" ? (
                          <NavLink
                            className={`${classes.white} normal1`}
                            style={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }
                            to="/Trainers"
                          >
                            Private Training
                          </NavLink>
                        ) : (
                          <NavLink
                            style={({ isActive }) =>
                              isActive ? activeStyle : undefined
                            }
                            className={`${classes.white} normal1`}
                            to="/ViewBooking"
                          >
                            View Booking
                          </NavLink>
                        )}
                      </div> */}

                      {/* ------------------Fighters----------------- */}
                      {/* <div className="col-lg-2 d-flex justify-content-center">
                        <NavLink
                          className={`${classes.white} normal1`}
                          to="/Fighters"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                        >
                          Fighters
                        </NavLink>
                      </div> */}
                      {/* ------------------------------------------- */}
                      <div
                        className="col-lg-2 d-flex justify-content-center"
                        onClick={(e) => handleClick(e, "#ContactUs")}
                      >
                        <NavLink
                          className={`${classes.white} normal1`}
                          to="/ContactUs"
                          style={({ isActive }) =>
                            isActive ? activeStyle : undefined
                          }
                          onClick={(e) => handleClick(e, "#ContactUs")}
                        >
                          Contact
                        </NavLink>
                      </div>

                      {/* <div className="col-lg-2">
                        {!state.auth ? (
                          <Nav.Link
                            className={`${classes.white} normal1`}
                            onClick={() => move("/Logins")}
                          >
                            Login
                          </Nav.Link>
                        ) : (
                          <Nav.Link
                            className={`${classes.white} normal1`}
                            onClick={() => handleLogout()}
                          >
                            Log Out
                          </Nav.Link>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="d-flex flex-row"> */}
      <Navbar
        className={`${classes.bgnavbar} d-lg-none PN w-100`}
        expand="lg"
        sticky="top"
        // isCollapse = isOpen
        collapseOnSelect={isOpen}
      >
        <Container>
          {/* d-flex justify-content-between align-items-center mbl-header */}
          <div className="d-flex align-items-center mbl-header col">
            <div className="xs-6">
              <Navbar.Brand
                href="#"
                style={{
                  height: "0px",
                  marginRight: "200px",
                }}
              >
                <img src="../Logo.png" className="navbar-logo" />
              </Navbar.Brand>
            </div>
            <div>
              {/* <button
                aria-controls="navbarScroll"
                type="button"
                aria-label="Toggle navigation"
                clas={`Header_small__UY7ya navbar-toggler collapsed xs-6 ${classes.navbtn}`}
              >
                 <span class="navbar-toggler-icon"></span> 
              </button> */}
            </div>
          </div>
          <div></div>
          <Navbar.Toggle
            aria-controls="navbarScroll"
            className={classes.small}
            //onClick={() => handleMenuBtnClick()}
            style={{
              borderColor: "transparent",
              left: -6,
              position: "relative",
              outline: "none",
            }}
          >
            <Button
              className={`navbar-mbl-btn navbar-toggler collapsed sm-6 ${classes.navbtn}`}
              onClick={(e) => {
                e.currentTarget.blur(); // Remove focus after click
                handleMenuBtnClick();
              }}
              style={{
                position: "absolute",
                left: 0,
                bottom: -10,
                border: "0px",
              }}
            >
              <div
                className="btn-icon"
                sx={{
                  marginBottom: "10px",
                }}
              >
                <div
                  className="btn-icon"
                  sx={{
                    marginBottom: "10px",
                  }}
                >
                  <div className="btn-line-icon"></div>
                  <div className="btn-line-icon"></div>
                  <div
                    className="btn-line-icon"
                    id="myElement"
                    ref={myIdRef}
                  ></div>
                  <div
                    className="btn-line-icon"
                    id="myElement1"
                    ref={myIdRef1}
                    style={{
                      marginTop: "18px",
                    }}
                  ></div>
                </div>
              </div>
              <span class="navbar-toggler-icon"></span>
            </Button>
          </Navbar.Toggle>
          <Navbar.Collapse id="navbarScroll" collapseOnSelect>
            <Nav className="my-2 py-4 px-2 m-md-auto d-flex align-items-center justify-content-center">
              <Nav.Link
                className={`${classes.white} `}
                eventKey="1"
                // onClick={() => move("/")}
                onClick={(e) => handleMobNavs(e, 0)}
              >
                Home
              </Nav.Link>
              {state.user?.role != "trainer" ? (
                <Nav.Link
                  to="/Uber-uns"
                  className={`${classes.white} `}
                  // onClick={() => move("/Uber-uns")}
                  onClick={(e) => handleMobNavs(e, 1)}
                  eventKey="2"
                >
                  Uber Uns
                </Nav.Link>
              ) : (
                <Nav.Link
                  to="/Uber-uns"
                  className={`${classes.white} `}
                  // onClick={() => move("/Uber-uns")}
                  onClick={(e) => handleMobNavs(e, 1)}
                  eventKey="2"
                >
                  Uber Uns
                </Nav.Link>
              )}
              {/* {state.user?.role != "trainer" ? ( */}
              <Nav.Link
                eventKey="3"
                // onClick={() => navigate("/Training")}
                onClick={(e) => handleMobNavs(e, 2)}
                className={`${classes.white} `}
              >
                Training & Preise
              </Nav.Link>

              {/* ) : (
                <Nav.Link
                  to="/ProfileDetail"
                  eventKey="3"
                  className={`${classes.white} `}
                >
                  Profile
                </Nav.Link>
              )} */}

              {/* -------------Fighters----------- */}
              {/* <Nav.Link
                className={`${classes.white} `}
                // onClick={() => navigate("/Fighters")}
                onClick={(e) => handleMobNavs(e, 3)}
                eventKey="4"
              >
                Fighters
              </Nav.Link> */}
              {/* -------------------------------- */}
              <Nav.Link
                className={`${classes.white} `}
                // onClick={(e) => handleClick(e, "#ContactUs")}
                onClick={(e) => handleMobNavs(e, 4)}
                eventKey="5"
              >
                Contact
              </Nav.Link>
              <div className="row">
                <div
                  className="col-6"
                  onClick={() =>
                    (window.location.href = Content[0]?.attributes.instaLink)
                  }
                >
                  {" "}
                  <img src="../Icon awesome-instagram.png" alt="Instagram" />
                </div>
                <div
                  className="col-6"
                  onClick={() =>
                    (window.location.href = Content[0]?.attributes.fbLink)
                  }
                >
                  {" "}
                  <img src="../Icon awesome-facebook-f.png" alt="Facebook" />
                </div>
              </div>
              {/* <Nav.Link
                className="smIcon m-auto"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = Content[0]?.attributes.fbLink)
                }
              >
                <img src="../Icon awesome-facebook-f.png" alt="Facebook" />
              </Nav.Link>
              <Nav.Link
                className="smIcon m-auto"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = Content[0]?.attributes.instaLink)
                }
              >
                <img src="../Icon awesome-instagram.png" alt="Instagram" />
              </Nav.Link> */}

              {/* {!state.auth ? (
                <Nav.Link
                  className={`${classes.white} normal1`}
                  eventKey="7"
                  onClick={() => move("/Logins")}
                >
                  Login
                </Nav.Link>
              ) : (
                <Nav.Link
                  eventKey="8"
                  className={`${classes.white} normal1`}
                  onClick={() => handleLogout()}
                >
                  Log Out
                </Nav.Link>
              )} */}
            </Nav>
            {/* <div className=" text-dark px-2 d-flex flex-column align-items-center ">
              <img
                src="../Icon awesome-facebook-f.png"
                className="me-4"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = Content[0]?.attributes.fbLink)
                }
              />
              <img
                src="../Icon awesome-instagram.png"
                className="me-4"
                style={{ cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = Content[0]?.attributes.instaLink)
                }
              />
            </div> */}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
