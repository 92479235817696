import React from "react";
import { Swal } from "sweetalert2";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form ,ErrorMessage} from "formik";
import { Field } from "formik";
import ImageUploading from "react-images-uploading";
import bussnessServices from "./../../Services/services/bussnessuser";
import { success } from "../../utilties/Messagehandler";
import { useAuth } from "../../Services/provideMain";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Header from "../../Header";
import useFetch from "../../Services/Apihook";
const ProfileDetail = () => {
  const navigate = useNavigate();
  const [active, setActive] = React.useState(false);
  const { loading:load, Content} = useFetch("contact-us-details");
  let { state, handleLogout } = useAuth();

  const [init, setInit] = React.useState({
    address: "",
    specialist: "",
    fightName: "",

    fightRecord: "",

    titles: "",
    monthyFees: "",
    medal: "",
    about: "",
  });
  const handleFormSubmit = (values) => {
    if (!active) {
      const formData = new FormData();

      formData.append("address", values.address);
      formData.append("specialist", values.specialist);
      formData.append("fightName", values.fightName);
      formData.append("fightRecord", values.fightRecord);
      formData.append("titles", values.titles);
      formData.append("monthyFees", values.monthyFees);
      formData.append("medal", values.medal);
      formData.append("userId", state.user._id);
      formData.append("about", values.about);
      formData.append("profile", images[0].file);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      bussnessServices.addBussness(formData, config).then(() => {
        success("Your Profile is updated");
        navigate("/");
      });
    } else {
      bussnessServices.updateBussness(state.user._id, values).then((val) => {
        success("Your Profile is updated");
        navigate("/");
      });
    }
  };
  React.useEffect(() => {
    bussnessServices.getSingleBussness(state.user._id).then((val) => {
      setInit(val.bussness);
      setActive(true);
    });
  }, []);
  const [images, setImages] = React.useState([]);
  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    console.log(imageList, addUpdateIndex);
    setImages(imageList);
  };
  return (
    <>
     <Header Content={Content}/>
    <div class="py-5 mainBookingContainer" style={{marginTop:"100px"}}>
      <div class="card mx-auto ClientBookFormCard shadow-lg">
        <div class="card-body">
          <div class="row">
            <div class="col-md-8">
              <Formik
                initialValues={init}
                onSubmit={handleFormSubmit}
                enableReinitialize={true}
                validationSchema={Yup.object().shape({
                  address: Yup.string()
               
                    .required("Address is required"),
                    specialist: Yup.string()
               
                    .required("specialist is required"),
                    fightName: Yup.string()
               
                    .required("fightName is required"),
                    fightRecord: Yup.string()
               
                    .required("fightRecord is required"),
                    titles: Yup.string()
               
                    .required("title is required"),
                    monthyFees: Yup.number().min(1,"  Fees must be greater then 0")
               
                    .required("Hourly Fees is required"),
                    medal: Yup.string()
               
                    .required("Experience is required"),
                    about: Yup.string()
               
                    .required("about is required"),

                })}
              >
                {({ values, isSubmitting, validateForm, setTouched }) => (
                  <Form>
                    <h3>Fill out your details</h3>
                    <hr class="solid" />
                    <div class="row">
                      <div class="col-md-6">
                        <label for="address" class="form-label BookLabels">
                          address<span class="star">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control inputTextfields"
                          id="Name"
                          name="address"
                        />
                        <ErrorMessage
                          component="div"
                          name="address"
                          className="errorField"
                        />
                      </div>
                      <div class="col-md-6">
                        <label for="Contact" class="form-label BookLabels">
                          specialist<span class="star">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control inputTextfields"
                          name="specialist"
                          id="Contact"
                        />
                          <ErrorMessage
                          component="div"
                          name="specialist"
                          className="errorField"
                        />
                        
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-6">
                        <label for="Email" class="form-label BookLabels">
                          fightName<span class="star">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control inputTextfields"
                          id="Email"
                          name="fightName"
                        />
                         <ErrorMessage
                          component="div"
                          name="fightName"
                          className="errorField"
                        />
                        <label for="Address" class="form-label BookLabels">
                          fightRecord<span class="star">*</span>
                        </label>
                        <Field
                          type="text"
                          class="form-control inputTextfields"
                          id="address"
                          name="fightRecord"
                        />
                          <ErrorMessage
                          component="div"
                          name="fightRecord"
                          className="errorField"
                        />
                      </div>
                      {!active && (
                        <ImageUploading
                          value={images}
                          onChange={onChange}
                          maxNumber={maxNumber}
                          dataURLKey="data_url"
                        >
                          {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                          }) => (
                            // write your building UI
                            <div className="upload__image-wrapper mt-3">
                              <button
                                type="button"
                                className="btn btn-info"
                                style={
                                  isDragging ? { color: "red" } : undefined
                                }
                                onClick={onImageUpload}
                                {...dragProps}
                              >
                                Click or Drop here
                              </button>
                              &nbsp;
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={onImageRemoveAll}
                              >
                                Remove all images
                              </button>
                              {imageList.map((image, index) => (
                                <div key={index} className="image-item">
                                  <img
                                    src={image["data_url"]}
                                    alt=""
                                    width="100"
                                  />
                                  <div className="image-item__btn-wrapper">
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={() => onImageUpdate(index)}
                                    >
                                      Update
                                    </button>
                                    <button
                                      type="button"
                                      className="btn"
                                      onClick={() => onImageRemove(index)}
                                    >
                                      Remove
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                        </ImageUploading>
                      )}
                      <div class="col-md-6">
                        <label
                          for="BuildingFloor"
                          class="form-label BookLabels"
                        >
                          Title<span class="star">*</span>
                        </label>
                        <Field
                          name="titles"
                          type="text"
                          class="form-control inputTextfields"
                          id="BuildingFloor"
                        />
                         <ErrorMessage
                          component="div"
                          name="titles"
                          className="errorField"
                        />
                      </div>
                      <div class="col-md-6">
                        <label
                          for="BuildingFloor"
                          class="form-label BookLabels"
                        >
                          Experience<span class="star">*</span>
                        </label>
                        <Field
                          name="medal"
                          type="text"
                          class="form-control inputTextfields"
                          id="BuildingFloor"
                        />
                         <ErrorMessage
                          component="div"
                          name="medal"
                          className="errorField"
                        />
                      </div>
                      <div class="col-md-6">
                        <label
                          for="BuildingFloor"
                          class="form-label BookLabels"
                        >
                          Per Hour Fees<span class="star">*</span>
                        </label>
                        <Field
                          name="monthyFees"
                          type="number"
                          class="form-control inputTextfields"
                          id="BuildingFloor"
                        />
                          <ErrorMessage
                          component="div"
                          name="monthyFees"
                          className="errorField"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-md-12">
                        <label for="Message" class="form-label BookLabels">
                          About<span class="star">*</span>
                        </label>
                        <Field
                          name="about"
                          type="text"
                          class="form-control inputTextfields"
                          id="Message"
                        />
                           <ErrorMessage
                          component="div"
                          name="about"
                          className="errorField"
                        />
                      </div>
                    </div>
                    <button type="submit" class="btn paybtn btn-success batn">
                      Update Profile
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default ProfileDetail;
