import axios from "axios";
class GenericServices {
  get = (url) =>
    new Promise((resolve, reject) => {
      axios
        .get("https://dadocms.thecbt.live/api/" + url)
        .then((res) => {
          resolve(res.data.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  post = (url, data) =>
    new Promise((resolve, reject) => {
      axios
        .post("https://dadocms.thecbt.live/api/" + url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  postconfig = (url, data, config) =>
    new Promise((resolve, reject) => {
      axios
        .post("https://dadocms.thecbt.live/api/" + url, data, config)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
  delete = (url) =>
    new Promise((resolve, reject) => {
      axios
        .delete("https://dadocms.thecbt.live/api/" + url)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });

  put = (url, data) =>
    new Promise((resolve, reject) => {
      axios
        .put("https://dadocms.thecbt.live/api/" + url, data)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err.response.data);
        });
    });
}
export default GenericServices;
