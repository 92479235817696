import React from "react";
import TrainerBanner from "./../../components/TrainersBanner/Banner";
import AllTrainers from "./../../components/AllTrainners/AllTrainers";
import Footer from "./../../Footer/Footer";
import useFetch from "../../Services/Apihook";
import Header from "../../Header";
import CmsService from "../../Services/CMS";

const Trainers = () => {
  const { loading: load, Content: Con } = useFetch("contact-us-details");
  const [Content, setContent] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    document.body.style.overflowY = "hidden";
    api();
  }, []);
  const api = async () => {
    const contents = await CmsService.getGetContent(
      "private-trannings-banners?populate=*"
    );
    setContent(contents);
    setTimeout(() => {
      setLoading(false);
      document.body.style.overflowY = "scroll";
    }, 500);
  };
  return (
    <>
      <div
        style={{
          opacity: loading ? "1" : "0",
          display: loading ? "block" : "none",
        }}
      >
        <div
          style={{
            width: "100%",

            position: "absolute",
            top: "0",
            background: "white",
            bottom: "0",
            height: "100vh",

            transition: "20000ms all fade",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1200",
          }}
        >
          <img
            src="../assets/logo2.png"
            style={{ display: loading ? "block" : "none", width: "30%" }}
          />
        </div>
      </div>
      {Content.length > 0 && (
        <div className="">
          <Header Content={Con} />

          <TrainerBanner Content={Content} />
          <AllTrainers />
          <Footer />
        </div>
      )}
    </>
  );
};
export default Trainers;
