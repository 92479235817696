import React from "react";

function TrainerWins({ title, index }) {
  let greys = [1, 2, 5, 6, 9, 10, 13, 14, 17, 18, 21, 22];
  let blacks = [3, 4, 7, 8, 11, 12, 15, 16, 19, 20, 23, 24];
  return (
    <div
      style={{
        background: greys.includes(index) ? "grey" : "transparent",
        padding: "10px",
        // width: "30vw",
        borderRight: `0.1px solid ${
          blacks.includes(index) ? "grey" : "transparent"
        }`,
      }}
      class="col-md-6 mt-1"
    >
      {title}
    </div>
  );
}

export default TrainerWins;
