import React, { useEffect, useState, useRef } from "react";
import useFetch from "../../Services/Apihook";
import classes from "./Schedule.module.css";
import { Button } from "react-bootstrap";

import StrapiTable from "../strapiTable/strapiTable";
const Schedule = () => {
  const { Content } = useFetch("calenders");
  const tableRef = useRef(null);

  console.log({ Content });
  const [tab1Point, setTab1Point] = useState("starting-point1");
  // const buttonRight = document.getElementById("slideRight");
  // const buttonLeft = document.getElementById("slideLeft");
  const scrollTableLeft = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft -= 100; // Adjust the scroll amount as needed
    }
  };

  const scrollTableRight = () => {
    if (tableRef.current) {
      tableRef.current.scrollLeft += 100; // Adjust the scroll amount as needed
    }
  };
  // buttonRight.onclick = function () {
  //   var container = document.getElementById("content");
  //   console.log(container);
  //   var currentPosition = container.scrollLeft;
  //   console.log(currentPosition);
  //   container.scrollTo({
  //     left: currentPosition - 300,
  //     behavior: "smooth",
  //   });
  //   console.log(currentPosition);
  //   //sideScroll(container, "right", 25, 100, 10);
  // };
  // buttonLeft.onclick = function () {
  //   document.getElementById("content").scrollLeft -= 20;
  // };

  // const parseHTML = (htmlString) => {
  //   const parser = new DOMParser();
  //   const doc = parser.parseFromString(htmlString, "text/html");
  //   return doc.body.firstChild;
  // };

  const hardcodedTable = `
  <figure class="table">
    <table>
      <tbody>
        <tr>
          <td>Name</td>
          <td>Email</td>
          <td>number</td>
        </tr>
        <tr>
          <td>Uzair</td>
          <td>u@gmai;.com</td>
          <td>123</td>
        </tr>
        <tr>
          <td>Qasim</td>
          <td>c@gmail.com</td>
          <td>125</td>
        </tr>
      </tbody>
    </table>
  </figure>
  `;

  const ScheduleTable = () => {
    return <div dangerouslySetInnerHTML={{ __html: hardcodedTable }} />;
  };

  const MainComponent = () => {
    return (
      <div>
        <ScheduleTable />
      </div>
    );
  };
  // Assuming your API response is stored in responseData
  // const scheduleTable = parseHTML(responseData.data.attributes.schedule_table);

  return (
    <div className="container-fluid">
      <div className="row pt-5">
        <div className="col-md-4 text-center text-md-start mt-md-0 mt-4 mb-2 mb-md-0">
          {/* <img src="./flip.png" className={classes.mblsize} /> */}
        </div>
        <div className="col-md-4">
          <h4 className={`${classes.head2} fw-bold text-center text-dark pb-5`}>
            Muay Thai Trainingsplan und Klassen überblick
          </h4>
        </div>
      </div>
      <div className="container">
        <div>
          <div className="table-btns d-flex justify-content-around d-md-none">
            <div className="left-btn-div">
              <Button
                id="scroll-left"
                onClick={scrollTableLeft}
                style={{
                  backgroundColor: "#232222",
                  border: "none",
                }}
              >
                &lt;
              </Button>
            </div>
            <div className="right-btn-div">
              <Button
                id="scroll-right"
                onClick={scrollTableRight}
                style={{
                  backgroundColor: "#232222",
                  border: "none",
                }}
              >
                &gt;
              </Button>
            </div>
          </div>
          <div
            class="table-responsive-md table-striped table-borderless tableWrapper"
            ref={tableRef}
            // style="overflow-x: scroll;white-space: nowrap;"
          >
            <table class="table" cellspacing="4">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                    id="starting-point1"
                  >
                    Zeiten{" "}
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Montag
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Dienstag
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Mittwoch
                  </th>
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Donnerstag
                  </th>{" "}
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Freitag
                  </th>{" "}
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                  >
                    Samstag
                  </th>{" "}
                  <th
                    scope="col "
                    className="thc text-center"
                    style={{ backgroundColor: "#232222" }}
                    id="ending-point1"
                  >
                    Sonntag
                  </th>
                </tr>
              </thead>
              <tbody id="content">
                {Content.length > 0 &&
                  Content.map((val) => (
                    <tr style={{ backgroundColor: "#E2C657" }}>
                      <td
                        className="text-center pt-3"
                        id="starting-point2"
                        style={{ backgroundColor: "#F3F3F3" }}
                      >
                        {val?.attributes.Time}
                      </td>
                      <td
                        className="text-center"
                        style={{ backgroundColor: "#E2C657" }}
                      >
                        <div className="fw-bold">
                          {val?.attributes?.MonClassName}
                        </div>
                        <div>{val?.attributes?.MonClassTime}</div>
                      </td>

                      <td
                        className="text-center"
                        style={{ backgroundColor: "#E2C657" }}
                      >
                        <div className="fw-bold">
                          {val?.attributes.TuesClassName}
                        </div>
                        <div>{val?.attributes.TuesClassTime}</div>
                      </td>
                      <td
                        className="text-center"
                        style={{ backgroundColor: "#E2C657" }}
                      >
                        <div className="fw-bold">
                          {val?.attributes.WedClassName}
                        </div>
                        <div>{val?.attributes.WedClassTime}</div>
                      </td>
                      <td className="text-center">
                        <div className="fw-bold">
                          {val?.attributes.ThursClassName}
                        </div>
                        <div>{val?.attributes.ThursClassTime}</div>
                      </td>
                      <td
                        className="text-center"
                        style={{ backgroundColor: "#E2C657" }}
                      >
                        <div className="fw-bold">
                          {val?.attributes.FriClassName}
                        </div>
                        <div>{val?.attributes.FriClassTime}</div>
                      </td>
                      <td className="text-center">
                        <div className="fw-bold">
                          {val?.attributes.SAClassName}
                        </div>
                        <div>{val?.attributes.SatClassTime}</div>
                      </td>
                      <td className="text-center">
                        <div className="fw-bold" id="ending-point">
                          {val?.attributes.SUNClassName}
                        </div>
                        <div>{val?.attributes.SunClassTime}</div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>

            {/* <ScheduleTable /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Schedule;
