import React, { useRef, useState } from "react";
import classes from "./Header.module.css";
import useFetch from "./Services/Apihook";
import { imageurl } from "./Services/urls";
import { Slide, Fade, Zoom, Roll, Bounce } from "react-awesome-reveal";
import moment from "moment";
import VisibilitySensor from "react-visibility-sensor";
import CountUp, { useCountUp } from "react-countup";
import Collapse from "react-bootstrap/Collapse";
const LatestNews = () => {
  const [expanded, setExpanded] = useState([]);
  const { Content: history } = useFetch(
    "home-latest-news?populate[latest_new_sub_heads][populate]=Image"
  );
  const newsDiv = useRef(null);
  console.log(history, "his");

  const { Content } = useFetch("home-gernel-stats?populate=*");
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState({
    0: 100,
    1: 100,
    2: 100,
  });
  const [displayHalfText, setDisplayHalfText] = React.useState({
    0: true,
    1: true,
    2: true,
  });
  const [open, setOpen] = useState({
    0: false,
    1: false,
    2: false,
  });
  const [viewPortEntered, setViewPortEntered] = React.useState(false);
  const increaseHeight = (val, index) => {
    console.log(index, window.innerHeight, "ff");
    if (index == 0) {
      //const div = document.getElementById("myDiv");
      const div = newsDiv.current;
      const newHeight = div.scrollHeight;
      console.log(newHeight);
      // setHeight((prev) => ({
      //   ...prev,
      //   //[String(index)]: 700,
      //   [String(index)]: newHeight,
      // }));
      setOpen((prev) => ({
        ...prev,
        0: true,
      }));
      decreaseHeight(val, 1);
      decreaseHeight(val, 2);
    }
    if (index == 1) {
      const div = newsDiv.current;
      const newHeight = div.scrollHeight;
      console.log(newHeight);
      setHeight((prev) => ({
        ...prev,
        //[String(index)]: 1030,
        [String(index)]: newHeight,
      }));
      decreaseHeight(val, 0);
      decreaseHeight(val, 2);
    }
    if (index == 2) {
      setHeight((prev) => ({
        ...prev,
        [String(index)]: 580,
      }));
      decreaseHeight(val, 0);
      decreaseHeight(val, 1);
    }

    // setHeight(window.innerHeight); // increase height by 100 pixels
  };
  const decreaseHeight = (e, index) => {
    console.log("decreasing height");
    setHeight((prev) => ({
      ...prev,
      [index]: 100,
    })); // decrease height by 100 pixels, minimum height 100
  };

  const [openIndexes, setOpenIndexes] = useState([]);

  const toggleCollapse = (index) => {
    console.log(openIndexes);
    setOpenIndexes((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        return prevIndexes.filter((item) => item !== index);
      } else {
        return [index];
      }
    });
  };
  const handleToggleCollapse = (index) => {
    // const halfDivText = document.getElementsByClassName("halfText_" + index);
    // const classList = halfDivText.classList;
    // classList.add("hidden");

    // if (displayHalfText[index]) {
    //   setDisplayHalfText((prev) => ({
    //     ...prev,
    //     [index]: [index],
    //   }));
    // } else {
    //   setDisplayHalfText((prev) => ({
    //     ...prev,
    //     [index]: [index],
    //   }));
    // }

    if (index == 0) {
      console.log(displayHalfText[0], " halftext");
      if (displayHalfText[index] == true) {
        setDisplayHalfText({
          0: false,
          1: true,
          2: true,
        });
      } else {
        setDisplayHalfText({
          0: true,
          1: true,
          2: true,
        });
        console.log("display half text is true on all objects at line 1s69");
        var blog = document.getElementById("Blog");
        blog.scrollIntoView();
      }
    } else if (index == 1) {
      if (displayHalfText[index] == true) {
        setDisplayHalfText({
          0: true,
          1: false,
          2: true,
        });
      } else {
        setDisplayHalfText({
          0: true,
          1: true,
          2: true,
        });
        console.log("display half text is true on all objects at line 1s69");
        var blog = document.getElementById("Blog");
        blog.scrollIntoView();
      }
    } else if (index == 2) {
      if (displayHalfText[index] == true) {
        setDisplayHalfText({
          0: true,
          1: true,
          2: false,
        });
      } else {
        setDisplayHalfText({
          0: true,
          1: true,
          2: true,
        });
        console.log("display half text is true on all objects at line 1s69");
        var blog = document.getElementById("Blog");
        blog.scrollIntoView();
      }
    }
    toggleCollapse(index);
  };
  return (
    <>
      {history.map((val) => (
        <>
          <div className={`${classes.MainHeads} p-auto`} id="Blog">
            <div class="container mt-3" id={"Blog"}>
              <div
                class="row ps-md-0 ps-0 d-flex justify-content-center m-auto my-auto align-middle"
                id="Services"
              >
                <div class="col-md-12 text-center  py-md-4 DB1 ">
                  <h4 className={`${classes.head2} fw-bold`}>
                    {val.attributes.Heading}
                  </h4>
                  <p className={`${classes.head} text-muted my-5 w-50 m-auto`}>
                    {" "}
                    {val.attributes.Description}
                  </p>
                </div>

                <div class="row  ">
                  {val.attributes.latest_new_sub_heads.data.map(
                    (val, index) => (
                      // <div class="col-md-4  m-auto ps-md-0 ps-4">
                      <div class="col-md-4 ps-md-0 ps-3">
                        <div
                          class="card text-left mb-5  bg-white rounded"
                          style={{ border: "0px", padding: 0 }}
                        >
                          <div style={{ position: "relative" }}>
                            <div style={{}}>
                              <img
                                style={
                                  {
                                    // height: "370px",
                                    // width: "370px",
                                    // maxWidth: "100%",
                                    // width: "350px",
                                  }
                                }
                                className={`${classes.responsive} w-100  shadow  bg-white rounded  card-img-top  rounded`}
                                src={`${imageurl}${val.attributes.Image.data.attributes.url}`}
                                alt=""
                              />
                            </div>
                            <div
                              style={{
                                position: "absolute",
                                textAlign: "center",
                                padding: "7px 0px",
                                left: "20px",
                                backgroundColor: "#E2C657",
                                color: "white",
                                width: "25%",
                                bottom: "22px",
                              }}
                              className="adjust"
                            >
                              <div
                                style={{ fontSize: "13px" }}
                                className="px-1"
                              >
                                {/* {moment(`${val.attributes.news_date}`).format(
                                  "YYYY-MM-DD"
                                )} */}
                                {val.attributes.news_date}
                              </div>
                            </div>
                          </div>

                          <div class="card-bod pt-3 ">
                            <div
                              ref={newsDiv}
                              id="newsDiv"
                              style={{
                                // height: `${height[String(index)]}px`,
                                // height: "100px",
                                border: "1px solid black",
                                overflow: "hidden",
                                transition: "height 0.5s ease",
                                border: "0px",
                              }}
                            >
                              {/* {height[String(index)] == 100 ? (
                                <button
                                  // onClick={(e) => increaseHeight(val, index)}
                                  // onCLick={(e) => setOpen()}
                                  onClick={() => toggleCollapse(index)}
                                  style={{
                                    background: "transparent",
                                    border: 0,
                                    color: "#e2c657",
                                    border: "1px solid #e2c657",
                                  }}
                                >
                                  <p
                                    style={{
                                      display: "flex",
                                      margin: "auto",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Show more
                                  </p>
                                </button>
                              ) : (
                                <button
                                  onClick={(e) => decreaseHeight(e, index)}
                                  style={{
                                    background: "transparent",
                                    border: 0,
                                    color: "#e2c657",
                                    border: "1px solid #e2c657",
                                  }}
                                >
                                  <p
                                    style={{
                                      display: "flex",
                                      margin: "auto",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    Show less
                                  </p>
                                </button>
                              )} */}

                              {/* <Collapse in={open}> */}
                              <div
                                style={{
                                  height: 120,
                                }}
                              >
                                <p
                                  className={`${classes.cardhead1} DR1 fw-bold`}
                                >
                                  {val.attributes.ShortDescription}
                                </p>
                              </div>
                              {/* </Collapse> */}
                              {/* <div
                                style={{
                                  height: 120,
                                }}
                              >
                                <p
                                  className={`${classes.cardhead1} DR1 fw-bold`}
                                >
                                  {val.attributes.ShortDescription}
                                </p>
                              </div> */}
                              {/* <div
                            style={{
                              height: `${height}px`,
                              // height: "100px",
                              border: "1px solid black",
                              overflow: "hidden",
                              transition: "height 0.5s ease",
                              border: "0px",
                            }}
                          > */}
                              <div>
                                {displayHalfText[index] ? (
                                  <div
                                    className={`halfText_${index} ${classes.cardhead2} DSB1 text-muted`}
                                    style={{
                                      transform: "none",
                                      transition: "none",
                                    }}
                                  >
                                    <p>
                                      {val.attributes.LongDescription.slice(
                                        0,
                                        50
                                      ) +
                                        "..." +
                                        val.attributes.LongDescription.slice(
                                          -50
                                        )}
                                    </p>
                                  </div>
                                ) : (
                                  ""
                                )}

                                <div className="fullText">
                                  <Collapse
                                    style={{
                                      transform: "none",
                                      transition: "none",
                                    }}
                                    in={openIndexes.includes(index)}
                                  >
                                    <div>
                                      <Slide triggerOnce={false}>
                                        <p
                                          className={`${classes.cardhead2} DSB1 text-muted`}
                                        >
                                          {val.attributes.LongDescription}
                                        </p>
                                      </Slide>
                                      <div
                                        // className="col-md-3 col-sm-3 pr-0 mr-0"
                                        className=""
                                        style={{
                                          display: "flex",
                                          justifyContent: "left",
                                        }}
                                      >
                                        <p
                                          style={{
                                            marginRight: "10px",
                                            fontSize: "12px",
                                          }}
                                          className="DSB1 text-muted"
                                        >
                                          Referenz:
                                        </p>{" "}
                                        {/* <button
                                        style={{
                                          marginLeft: "0px",
                                          background: "transparent",
                                          border: "none",
                                        }}
                                      >
                                        Link
                                      </button> */}
                                        {/* <a
                                          href={val.attributes.news_link}
                                          className="news-link"
                                          style={{
                                            fontSize: "10px",
                                          }}
                                        > */}
                                        <div
                                          onClick={
                                            (e) =>
                                              window.open(
                                                val.attributes.news_link,
                                                "_blank"
                                              )
                                            // console.log("ref clicked!")
                                          }
                                        >
                                          {/* <p
                                            style={{
                                              fontSize: "10px",
                                              color: "blue",
                                            }}
                                          >
                                            {val.attributes.news_link}
                                          </p> */}
                                          <p
                                            style={{
                                              color: "#e2c657",
                                              fontSize: "12px",
                                              cursor: "pointer",
                                            }}
                                          >
                                            Klicken Sie für weitere
                                            Informationen
                                          </p>
                                        </div>
                                        {/* </a> */}
                                        {/* <p
                                        onClick={
                                          (window.location.href =
                                            val.attributes.news_link)
                                        }
                                        >
                                          Link
                                        </p> */}
                                      </div>
                                      {/* <p
                                        style={{
                                          color: "#e2c657",
                                          fontSize: "10px",
                                        }}
                                      >
                                        Klicken Sie für weitere Informationen
                                      </p> */}
                                    </div>
                                  </Collapse>
                                </div>
                              </div>

                              {/* <Collapse in={openIndexes.includes(index)}>
                                <p
                                  className={`${classes.cardhead2} DSB1 text-muted`}
                                >
                                  {val.attributes.LongDescription}
                                </p>
                              </Collapse> */}

                              <div className="row" style={{}}>
                                <div className="col-12">
                                  <div className="row">
                                    {/* <div
                                      // className="col-md-8 col-sm-8 pl-0 ml-0"
                                      className="col-6"
                                      style={{
                                        display: "flex",
                                        justifyContent: "left",
                                      }}
                                    >
                                    </div>
                                     */}
                                    <div className="col-2"></div>
                                  </div>
                                </div>
                                <div
                                  className="col-12"
                                  style={{
                                    display: "flex",
                                    justifyContent: "right",
                                  }}
                                >
                                  <button
                                    // onClick={(e) => increaseHeight(val, index)}
                                    // onCLick={(e) => setOpen()}
                                    onClick={(e) => handleToggleCollapse(index)}
                                    style={{
                                      background: "transparent",
                                      border: 0,
                                      color: "#e2c657",
                                      // border: "px solid #e2c657",
                                    }}
                                  >
                                    <p
                                      style={{
                                        display: "flex",
                                        margin: "auto",
                                        fontWeight: "bold",
                                        color: "#e2c657",
                                      }}
                                    >
                                      {console.log(openIndexes)}
                                      {
                                        // {openIndexes.index
                                        // ? "Show Less"
                                        // : "Show More"}
                                      }
                                      {openIndexes.length
                                        ? openIndexes.map((i) =>
                                            i == index
                                              ? "...See Less"
                                              : "...See More"
                                          )
                                        : "...See More"}
                                    </p>
                                  </button>
                                </div>
                              </div>
                            </div>
                            {/* {height[String(index)] == 100 ? (
                              <button
                                // onClick={(e) => increaseHeight(val, index)}
                                // onCLick={(e) => setOpen()}
                                onClick={() => toggleCollapse(index)}
                                style={{
                                  background: "transparent",
                                  border: 0,
                                  color: "#e2c657",
                                  border: "1px solid #e2c657",
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    margin: "auto",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Show more
                                </p>
                              </button>
                            ) : (
                              <button
                                onClick={(e) => decreaseHeight(e, index)}
                                style={{
                                  background: "transparent",
                                  border: 0,
                                  color: "#e2c657",
                                  border: "1px solid #e2c657",
                                }}
                              >
                                <p
                                  style={{
                                    display: "flex",
                                    margin: "auto",
                                    fontWeight: "bold",
                                  }}
                                >
                                  Show less
                                </p>
                              </button>
                            )} */}
                            {/* </div> */}
                            <div></div>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
};

export default LatestNews;
