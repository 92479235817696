import moment from "moment";
import React from "react";
import Swal from "sweetalert2";
import { useAuth } from "../../Services/provideMain";
import bookingServices from "./../../Services/services/booking";

const Cancel = () => {
  const [booking, setBooking] = React.useState([]);
  const { state } = useAuth();
  console.log(booking);
  React.useEffect(() => {
    bookingServices.getBooking(state.user._id).then((val) => {
      setBooking(val.Booking.filter((val) => val.status == 2));
    });
  }, []);

  return (
    <ul class="list-group clipdow">
      <li class="list-group-item">
        {booking.length > 0 ? (
          booking.map(
            (val,index) =>
              val.status == 2 && (
                <div class="row py-2">
                  <div class="col-md-6">
                  <h5>{index+1} :Name:{val.Name}</h5>
                   
                    <h6 class="info">
                      Date:{val.Date} . Time:{val.Time} . Price Per Hour:{val.Price}{" "}
                    </h6>
                  </div>
                  <div class="col-md-6">
                    <div class="row">
                      <button
                        type="button"
                        class="btn btn-outline-dark actionbtn"
                        onClick={() =>
                          Swal.fire({
                       
                            title: "Detail",
                       
                            confirmButtonColor: '#E2C657',
                      background:"#151414",
                            confirmButtonText: "Ok",
                            html: `
                            <div style="font-weight:bold">Name:${val.Name}</div>
                          
                              <div style="font-weight:bold">Price:${val.Price}</div>
                              <div style="font-weight:bold">Building:${val.Building}</div>
                              <div style="font-weight:bold">Address:${val.Address}</div>
                              <div style="font-weight:bold">City:${val.City}</div>
                              <div style="font-weight:bold">State:${val.State}</div>
                              <div style="font-weight:bold">Message:${val.Message}</div>
                          
                         
                      `,

                            confirmButtonText: "Ok",
                          })
                        }
                      >
                        View Booking Form
                      </button>
                    </div>
                  </div>
                </div>
              )
          )
        ) : (
          <h1>No Booking</h1>
        )}
      </li>
    </ul>
  );
};

export default Cancel;
