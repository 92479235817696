import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import CountUp from "react-countup";
import classes from "./Boxing.module.css";
import TrainingHeadComponent from "../../pages/Tranings/TrainingHeadComponent";
import { useState } from "react";
import { useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import BoxClasses from "./Boxing.module.css";
const Boxing = () => {
  const navigate = useNavigate();
  const [trainingTab, setTrainingTab] = useState(0);

  const handleChecking = (e) => {
    console.log(e, "clicked");
    setTrainingTab(e);
  };

  return (
    <div>
      {/* <div className={classes.bgcolor}> */}
      <div className="container">
        <div className={`row px-2 g-3 mt-lg-5  ml-0 ${classes.topText}`}>
          <div className={`g-3 mt-lg-5   pt-2 ml-0 ${classes.topText}`}>
            <div className={`g-3 mt-1 ml-0 ${classes.topText}`}>
              {/* <div style={{ background: "yellow" }}> */}
              <div className="container">
                <h4 className="title Pb">
                  {/* {val.attributes.Heading} */}
                  Trainingsprogramme und Mitgliedschaftspreise im Dado Gym
                </h4>
                <p>
                  {" "}
                  Willkommen im Dado Gym, wo wir auf jeden individuellen Schritt
                  deiner Muay Thai Reise eingehen. Egal, ob du Anfänger bist und
                  die Grundlagen erlernen möchtest, ein fortgeschrittener
                  Schüler, der seine Techniken verfeinern will, oder jemand, der
                  individuelles Training benötigt - wir haben das Fachwissen und
                  die Programme, um deine Bedürfnisse zu erfüllen. Unsere
                  erfahrenen Trainer sind leidenschaftlich darum bemüht, dir bei
                  der Erreichung deiner Ziele zu helfen, sei es für Fitness,
                  Selbstverteidigung oder den Wunsch, auf professionellem Niveau
                  zu konkurrieren. Schließe dich unserer unterstützenden
                  Gemeinschaft an und entdecke die transformative Kraft von Muay
                  Thai in einer einladenden und inklusiven Umgebung.
                </p>
              </div>
              <Tabs
                style={{ color: "white " }}
                defaultActiveKey={0}
                id="uncontrolled-tab-example"
                className="mb-3"
                onSelect={(e) => handleChecking(e)}
              >
                <Tab eventKey={0} title="Anfänger" style={{ color: "white " }}>
                  {" "}
                </Tab>
                <Tab eventKey={1} title="Fortgeschrittene"></Tab>
                <Tab eventKey={2} title=" Personaltrainings"></Tab>
                <Tab eventKey={3} title="  Pro/Wettkämpfer"></Tab>
              </Tabs>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <TrainingHeadComponent
        trainingTab={trainingTab}
        setTrainingTab={setTrainingTab}
      />
    </div>
  );
};

export default Boxing;
