import React, { useEffect, useState } from "react";
// import { Flip,Hinge ,JackInTheBox} from 'react-awesome-reveal';
import { useNavigate } from "react-router-dom";
import classes from "./Header.module.css";
import { imageurl } from "./Services/urls";
import { memo } from "react";
import useFetch from "./Services/Apihook";
import ProgressiveImage from "react-progressive-graceful-image";
//import Insta from "../assets/Instagram.png";
//import Twitter from "../assets/twitter.png";
//import Facebook from "../assets/fb.png";
import Insta from "./assets/Instagram.png";
import Facebook from "./assets/fb.png";
const Banner = ({ Content }) => {
  const navigate = useNavigate();
  //const { loading: load, Content } = useFetch("contact-us-details");
  // const [load, setLoad] = React.useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 1350,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  const divElement = document.getElementById("scroll-removable-div");

  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const currentPosition = window.scrollY || window.pageYOffset;
    setScrollPosition(currentPosition);
    console.log(currentPosition, scrollPosition);
    // if (currentPosition > 200) {
    //   const element = document.getElementById("scrollRemovableDiv");
    //   element.style.display = "none";
    // }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    Content.length > 0 &&
    Content.map((val, index) => (
      <>
        <ProgressiveImage
          src={`${imageurl}${val.attributes.BannerImage.data.attributes.url}`}
          placeholder=""
        >
          {(src, loading) => {
            return loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  background: "black",

                  // maxHeight: "100vh",
                }}
                className="check"
                role="status"
              ></div>
            ) : (
              <div style={{ position: "relative" }} id="home">
                <img
                  class="responsives"
                  style={{
                    width: "100%",
                    objectFit: "cover",
                    maxHeight: "100vh",
                    // maxWidth: "100px",
                    marginTop: "80px",
                    // filter:
                    //   "brightness(1.2) contrast(0.5) sepia(0.9) hue-rotate(45deg)",
                  }}
                  src={src}
                  alt="sea beach"
                />
                {/* <div
                  className="overlay"
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    // backgroundColor: "#e2c657",
                    // opacity: "0.5",
                  }}
                >
                  <p
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto",
                      textAlign: "center",
                      color: "balck",
                      padding: "10%",
                      opacity: 1,
                    }}
                  >
                    Entfessle dein Fitnesspotenzial in einer dynamischen
                    Fitnessstudio-Gemeinschaft. Erreiche deine Ziele mit
                    erfahrenen Trainern, modernsten Einrichtungen und
                    maßgeschneiderten Trainingsprogrammen.
                  </p>
                </div> */}

                <div
                  className={`d-md-none ${classes.forMobOnly}`}
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50% )",
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: 1,
                    backgroundColor: "rgb(40 35 42 /  85%)",
                    // zIndex: 100,
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      margin: "auto",
                      padding: "10px",
                      fontSize: "0.7em",
                    }}
                  >
                    {val.attributes.Description}

                    <button
                      onClick={scrollToTop}
                      style={{
                        marginX: "50%",
                        display: "flex",
                        justifyContent: "center",
                        paddingInline: 4,
                        paddingBlock: 2,
                        marginLeft: 10,
                        backgroundColor: "#E2C657ed",
                        color: "white",
                        border: "none",
                        borderRadius: "2px",
                        marginLeft: "50px",
                      }}
                    >
                      Join us
                    </button>
                  </p>
                </div>
                <div
                  className={`d-md-none ${classes.forMobOnly}`}
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50% )",
                    color: "white",
                    position: "absolute",
                    top: "18%",
                    left: "5%",
                    opacity: 1,
                    backgroundColor: "rgb(40 35 42 /  85%)",
                    // zIndex: 100,
                  }}
                >
                  {/* <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                    // className={classes.scrollRemovableDiv}
                    id="scrollRemovableDiv"
                  >
                    <img
                      src={`${Insta}`}
                      className={classes.icon}
                      onClick={() =>
                        (window.location.href =
                          // Content[0]?.attributes.instaLink
                          "https://www.instagram.com/dadogym/")
                      }
                      style={{
                        marginBottom: "15px",
                        padding: "5px",
                      }}
                    />
                    <img
                      src={`${Facebook}`}
                      className={classes.icon}
                      onClick={() =>
                        (window.location.href =
                          // Content[0]?.attributes.instaLink
                          "https://m.facebook.com/checkpoint/1501092823525282/?next=https%3A%2F%2Fm.facebook.com%2Ftherealdadogym%3Fwtsid%3Drdr_0zwXGd2PiXkH47R2b&wtsid=rdr_0zwXGd2PiXkH47R2b")
                      }
                      style={{
                        padding: "5px",
                      }}
                    />
                  </div> */}
                </div>
                <div
                  style={{
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50% )",
                    color: "white",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    opacity: 1,
                    zIndex: "100",
                  }}
                >
                  <h2 className="Pb d-sm-block d-none text-center">
                    {val.attributes.Title}
                  </h2>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      //backgroundColor: "rgb(150 150 150 / 85%)",
                      backgroundColor: "rgb(40 35 42 /  85%)",
                      //backgroundColor: "rgb(220, 220, 220)",
                      borderRadius: "5px",
                    }}
                  >
                    <p
                      className="d-sm-block d-none text-center"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        margin: "auto",
                        padding: "4px",
                        textAlign: "center",
                        width: "400px",
                        fontWeight: "bold",
                        color: "white",
                        marginLeft: "7px",
                      }}
                    >
                      {val.attributes.Description}

                      <button
                        onClick={scrollToTop}
                        style={{
                          paddingInline: 4,
                          paddingBlock: 2,
                          marginLeft: 10,
                          backgroundColor: "#E2C657ed",
                          color: "white",
                          border: "none",
                          borderRadius: "2px",
                        }}
                      >
                        Join us
                      </button>
                    </p>

                    <div
                      className="d-sm-none d-block text-center "
                      style={{ marginTop: "120px" }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          }}
        </ProgressiveImage>
      </>
    ))
  );
};

export default memo(Banner);
