import React from "react";
import TrainerBanner from "./../../components/TrainersBanner/Banner";
import AllTrainers from "./../../components/AllTrainners/AllTrainers";
import Footer from "./../../Footer/Footer";
import AllFighters from "../../components/AllTrainners/AllFighters";
//import FightersBanner from "../../components/TrainersBanner/FightersBanner";
import useFetch from "../../Services/Apihook";
import Header from "../../Header";

const Fighters = () => {
  const { loading: load, Content } = useFetch("contact-us-details");
  return (
    <>
      <Header Content={Content} />
      <AllFighters />
      <Footer />
    </>
  );
};
export default Fighters;
