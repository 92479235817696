// import Banner from "./../../Banner";
import BannerCart from "./../../BannerCart";
import WhoWeAre from "./../../WhoWeAre";
import LatestNews from "./../../LatestNews";
import Clients from "./../../Clients";
import Tranning from "./../../Traning";
import Footer from "./../../Footer/Footer";

import useFetch from "../../Services/Apihook";
import React, { Suspense } from "react";
import ContactUs from "../../components/ContactUs/ContactUs";
import Header from "../../Header";
import CmsService from "../../Services/CMS";
import { useLocation } from "react-router-dom";
const Banner = React.lazy(() => import("./../../Banner"));
const Home = () => {
  // const { loading, Content } = useFetch("home-banners?populate=*");
  const [Content, setContent] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const { loading: load, Content: Con } = useFetch("contact-us-details");
  React.useEffect(() => {
    document.body.style.overflowY = "hidden";
    api();
  }, []);
  const api = async () => {
    const contents = await CmsService.getGetContent("home-banners?populate=*");
    setContent(contents);
    setTimeout(() => {
      setLoading(false);
      document.body.style.overflowY = "scroll";
    }, 500);
  };
  return (
    <>
      <div
        style={{
          opacity: loading ? "1" : "0",
          display: loading ? "block" : "none",
        }}
      >
        <div
          style={{
            width: "100%",

            position: "absolute",
            top: "0",
            background: "white",
            bottom: "0",
            height: "100vh",

            transition: "20000ms all fade",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1200",
          }}
        >
          <img
            src="../assets/logo2.png"
            style={{ display: loading ? "block" : "none", width: "30%" }}
          />
        </div>
      </div>
      {Content.length > 0 && (
        <div className="">
          <Header Content={Con} />
          <Banner Content={Content} />

          {/* <BannerCart /> */}
          {/* <WhoWeAre /> */}
          <LatestNews />
          <Clients />
          {/* <Tranning /> */}

          <ContactUs />
          <Footer />
        </div>
      )}
    </>
  );
};

export default Home;
