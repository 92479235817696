import GenericServices from "./GenericServices";
class BookingServices extends GenericServices {
  constructor() {
    super();
  }
  addBooking = (data) => {
    return this.post("Booking", data);
  };
  addAdminBooking = (data) => {
    return this.post("Booking/AdminBooking", data);
  };
  getBooking = (id) => {
    return this.get("Booking/owner/" + id);
  };
  serviceBookings = (id) => {
    return this.get("booking/serviceBookings/" + id);
  };

  updatebooking = (id,data) => {
    return this.put("Booking/" + id,data);
  };
}
let bookingServices = new BookingServices();
export default bookingServices;
